import {
  Home,
  DataBannerList,
  DataIklanList,
  Permohonan,
  SemuaPermohonan,
  SemuaPermohonanEdit,
  PermohonanAdd,
  PermohonanEdit,
  PermohonanValidasi,
  CekPlotUploadBayar,
  CekPlotVerifikasiBayar,
  CekPlotInputHasil,
  HitungBiayaJadwal,
  HitungBiayaJadwalSet,
  KonfirmasiJadwal,
  PersetujuanJadwal,
  PembayaranUploadBayar,
  PembayaranVerifikasiBayar,
  PengukuranSet,
  Pengukuran,
  Pengolahan,
  PengolahanUpload,
  LegalisasiGu,
  HasilAkhir,
  HasilAkhirUpload,
  LegalisasiGuUpload,
} from "src/views/app";

const routes_client = [
  { path: "/home", exact: true, name: "HOME", component: Home },
  {
    path: "/data-banner-list",
    exact: true,
    name: "DATA BANNER",
    component: DataBannerList,
  },
  {
    path: "/data-iklan-list",
    exact: true,
    name: "DATA IKLAN",
    component: DataIklanList,
  },
  // new refoldering
  {
    path: "/permohonan",
    exact: true,
    name: "DATA PERMOHONAN",
    component: Permohonan,
  },
  {
    path: "/semua",
    exact: true,
    name: "DATA PERMOHONAN",
    component: SemuaPermohonan,
  },
  {
    path: "/semua/edit/:id",
    exact: true,
    name: "PERBARUI PERMOHONAN",
    component: SemuaPermohonanEdit,
  },
  {
    path: "/permohonan/add",
    exact: true,
    name: "TAMBAH PERMOHONAN",
    component: PermohonanAdd,
  },
  {
    path: "/permohonan/edit/:id",
    exact: true,
    name: "PERBARUI PERMOHONAN",
    component: PermohonanEdit,
  },
  {
    path: "/permohonan/validasi/:id",
    exact: true,
    name: "VALIDASI PERMOHONAN",
    component: PermohonanValidasi,
  },
  {
    path: "/cekplot-upload-bayar",
    exact: true,
    name: "UPLOAD PEMBAYARAN CEK PLOT",
    component: CekPlotUploadBayar,
  },
  {
    path: "/cekplot-verifikasi-bayar",
    exact: true,
    name: "VERIFIKASI PEMBAYARAN CEK PLOT",
    component: CekPlotVerifikasiBayar,
  },
  {
    path: "/cekplot-input-hasil",
    exact: true,
    name: "INPUT HASIL CEK PLOT",
    component: CekPlotInputHasil,
  },
  {
    path: "/hitung-biaya-penjadwalan",
    exact: true,
    name: "HITUNG BIAYA & PENJADWALAN",
    component: HitungBiayaJadwal,
  },
  {
    path: "/hitung-biaya-penjadwalan/set/:id",
    exact: true,
    name: "ATUR",
    component: HitungBiayaJadwalSet,
  },
  {
    path: "/pembayaran-upload",
    exact: true,
    name: "UPLOAD PEMBAYARAN LAYANAN",
    component: PembayaranUploadBayar,
  },
  {
    path: "/pembayaran-verifikasi",
    exact: true,
    name: "VERIFIKASI PEMBAYARAN LAYANAN",
    component: PembayaranVerifikasiBayar,
  },
  {
    path: "/konfirmasi-jadwal",
    exact: true,
    name: "KONFIRMASI JADWAL",
    component: KonfirmasiJadwal,
  },
  {
    path: "/persetujuan-jadwal",
    exact: true,
    name: "PERSETUJUAN JADWAL",
    component: PersetujuanJadwal,
  },
  {
    path: "/pengukuran",
    exact: true,
    name: "PENGUKURAN",
    component: Pengukuran,
  },
  {
    path: "/pengukuran/ukur/:id",
    exact: true,
    name: "UKUR ",
    component: PengukuranSet,
  },
  {
    path: "/pengolahan",
    exact: true,
    name: "PENGOLAHAN",
    component: Pengolahan,
  },
  {
    path: "/pengolahan/upload/:id",
    exact: true,
    name: "UPLOAD",
    component: PengolahanUpload,
  },
  {
    path: "/permohonan-legalisasi-gu",
    exact: true,
    name: "PERMOHONAN LEGALISASI GU",
    component: LegalisasiGu,
  },
  {
    path: "/permohonan-legalisasi-gu/upload/:id",
    exact: true,
    name: "UPLOAD",
    component: LegalisasiGuUpload,
  },
  {
    path: "/hasil-akhir",
    exact: true,
    name: "HASIL AKHIR",
    component: HasilAkhir,
  },
  {
    path: "/hasil-akhir/upload/:id",
    exact: true,
    name: "UPLOAD",
    component: HasilAkhirUpload,
  },
];

export default routes_client;
