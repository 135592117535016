import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { setFormInfoUser } from ".";
import { setImgPreview, loadingBar } from "./globalAction";

export const setDataUserInfo = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/User/GetInfoUser`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        localStorage.setItem("DATA_USER", JSON.stringify(data.Data));

        dispatch(setFormInfoUser("Username", data.Data.Username));
        dispatch(setFormInfoUser("Email", data.Data.Email));
        dispatch(setFormInfoUser("FirstName", data.Data.FirstName));
        dispatch(setFormInfoUser("MiddleName", data.Data.MiddleName));
        dispatch(setFormInfoUser("LastName", data.Data.LastName));
        dispatch(setFormInfoUser("MobileNumber", data.Data.MobileNumber));
        dispatch(setFormInfoUser("PhoneNumber", data.Data.PhoneNumber));
        dispatch(setFormInfoUser("Address", data.Data.Address));
        dispatch(setFormInfoUser("IdRole", data.Data.Roles[0].IdRole));

        dispatch(
          setImgPreview(
            "FILE_IMAGE_PROFILE_PREVIEW",
            `${data.Data.FileImage}?${new Date().getTime()}`
          )
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataUserInfoSession = () => {
  return (dispatch) => {
    http.get(`${URLSVC}/User/GetInfoUser`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        var dataUser = localStorage.getItem("DATA_USER");
        dataUser = JSON.parse(dataUser);
        dispatch({ type: "DATA_USER_SESSION", payload: dataUser });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
