import React, { useEffect } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable, ReactDatePicker, Upload } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  apiUploadBuktiBayar,
  setDataPermohonans,
  setDataUploadBayar,
} from "src/config/redux/action";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import NumberFormat from "react-number-format";

const PembayaranUploadBayar = () => {
  const { dataUploadBayars, modalUploadBayar, formUploadBayar } = useSelector(
    (state) => state.pembayaranReducer
  );
  const dispatch = useDispatch();

  const { NoPermohonan, Nama, Email, RpBayar, RpTotal } = formUploadBayar;

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("NoPermohonan", NoPermohonan);
    setValue("Nama", Nama);
    setValue("Email", Email);
  }, [NoPermohonan, Nama, Email]);

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_UPLOAD_BAYARS", "pembayaran"));
  }, [dispatch]);

  const btnUploadBayar = (data) => dispatch(apiUploadBuktiBayar(data));

  const onFileBuktiBayar = (e) => {
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target, [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
    ]);
    const file = e.target.files[0];

    if (file !== undefined) {
      setValue("BuktiBayar", file);
    } else {
      setValue("BuktiBayar", null);
    }
  };

  const handleModal = (idPermohonan) => {
    var idFile = document.getElementById(`fileBuktiBayar`);
    idFile.value = idFile.defaultValue;

    showModal();
    reset();
    setValue("IdPermohonan", idPermohonan);
    dispatch(setDataUploadBayar(idPermohonan));
  };

  const showModal = () =>
    dispatch({
      type: "MODAL_UPLOAD_BAYAR",
      payload: !modalUploadBayar,
    });

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleModal(rowData.IdPermohonan)}
            >
              <CIcon name="cil-cloud-upload" /> UPLOAD BUKTI
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          {/* {rowData.StatusProses === "" && (
            <span className="badge badge-warning">Dalam Proses</span>
          )}
          {rowData.StatusProses === "Berkas Valid/Lengkap" && (
            <span className="badge badge-success">{rowData.StatusProses}</span>
          )}
          {rowData.StatusProses === "Berkas Tidak Valid/Lengkap" && (
            <span className="badge badge-danger">{rowData.StatusProses}</span>
          )} */}
          <span className="badge badge-success">{rowData.StatusProses}</span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataUploadBayars}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>
      <CModal show={modalUploadBayar} onClose={showModal}>
        <CModalHeader closeButton>DETAIL PERMOHONAN</CModalHeader>
        <form id="form" onSubmit={handleSubmit(btnUploadBayar)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>

                <div className="form-group">
                  <label>TANGGAL BAYAR</label>
                  <Controller
                    control={control}
                    defaultValue={new Date()}
                    name="TanggalBayar"
                    render={({ field, onChange, value }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Bayar..."
                        selected={field.value}
                        value={getValues("TanggalBayar")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                        maxDate={new Date()}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.TanggalBayar && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
                <div className="form-group">
                  <label>JUMLAH BAYAR</label>
                  <Controller
                    name="RpBayar"
                    control={control}
                    defaultValue={RpTotal}
                    rules={{
                      required: "Kolom wajib diisi",
                      minLength: {
                        value: 4,
                        message: "Angka harus lebih dari 3 digit",
                      },
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        thousandSeparator={true}
                        className="form-control"
                        inputmode="numeric"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Ketikan Jumlah Bayar..."
                      />
                    )}
                  />
                  {errors.RpBayar && (
                    <span className="text-danger">
                      {errors.RpBayar?.message}
                    </span>
                  )}
                </div>
                <div className="alert alert-primary">
                  <b>Info Upload File</b> <br />
                  1. Ukuran file maksimal 2MB <br />
                  2. Format file yang digunakan wajib .pdf .png .jpg
                </div>
                <div className="form-group">
                  <label>BUKTI PEMBAYARAN</label>
                  <Upload
                    id="fileBuktiBayar"
                    onChange={(e) => onFileBuktiBayar(e)}
                  />
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              UPLOAD BUKTI BAYAR
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </>
  );
};

export default PembayaranUploadBayar;
