import React, { useEffect } from "react";
import { CCard } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { setDataPermohonans } from "src/config/redux/action";
import { useHistory } from "react-router-dom";

const HasilAkhir = () => {
  const history = useHistory();
  const { dataHasilAkhirs } = useSelector((state) => state.hasilAkhirReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_HASIL_AKHIRS", "hasil-akhir"));
  }, [dispatch]);

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() =>
                history.push(`/hasil-akhir/upload/${rowData.IdPermohonan}`)
              }
            >
              <CIcon name="cil-cloud-upload" /> UPLOAD
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          {/* {rowData.StatusProses === "" && (
            <span className="badge badge-warning">Dalam Proses</span>
          )}
          {rowData.StatusProses === "Berkas Valid/Lengkap" && (
            <span className="badge badge-success">{rowData.StatusProses}</span>
          )}
          {rowData.StatusProses === "Berkas Tidak Valid/Lengkap" && (
            <span className="badge badge-danger">{rowData.StatusProses}</span>
          )} */}
          <span className="badge badge-warning">
            {rowData.StatusProses || "-"}
          </span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];
  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataHasilAkhirs}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>
    </>
  );
};

export default HasilAkhir;
