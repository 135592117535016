import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormLogin = (formType, formValue) => {
  return { type: "FORM_LOGIN", formType, formValue };
};

export const apiLogin = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("usernameOrEmail", iData.UsernameOrEmail);
    fd.append("password", iData.Password);
    console.log("coba", iData);
    http.post(`${URLSVC}/Auth/Login`, fd).then((res) => {
      console.log("fd", fd);
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        localStorage.setItem("TOKEN", data.Data);
        Swal.fire("Berhasil", `Selamat datang!!!`, "success").then(
          () => (window.location = "/")
        );
        // dispatch(setDataUserInfo())
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiResetPassword = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("UsernamOrEmail", iData.UsernameOrEmailPass);

    http.post(`${URLSVC}/User/ResetPassword`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Reset password berhasil dikirim, silahkan cek email anda!`,
          "success"
        );
        dispatch({ type: "MODAL_LUPA_PASSWORD", payload: false });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
