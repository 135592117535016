import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter style={{ backgroundColor: "#F3F5FC" }} fixed={false}>
      <div className="mfs-auto">
        <span className="mr-1 text-dark">Powered by</span>
        <a
          href="https://ptmki.co.id/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={"assets/images/logo-mki.png"}
            name="logo-negative"
            width="auto"
            height="20px"
            alt="logo-mki"
          />
        </a>
        <span className="ml-1 text-dark">
          &copy; {new Date().getFullYear()}
        </span>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
