import React, { useState } from "react";
import {
  CNavbar,
  CToggler,
  CNavbarBrand,
  CCollapse,
  CNavbarNav,
  CNavLink,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const TheHeaderGuest = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CNavbar expandable="sm" color="primary">
      <CToggler inNavbar onClick={() => setIsOpen(!isOpen)} />
      <CNavbarBrand className="d-flex align-items-center" to={"/"}>
        <img
          className="mr-3"
          src="https://member.maski.or.id/assets/images/logo-surlis.png"
          width="30px"
          height="30px"
          alt="logo-surlis"
        ></img>
        <b>SURLIS</b>
      </CNavbarBrand>
      <CCollapse show={isOpen} navbar>
        <CNavbarNav>
          <CDropdown inNav>
            <CDropdownToggle color="primary" className="font-weight-bold">
              <CIcon name="cil-plus" className="mr-1" />
              Permohonan
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem to={"/daftar-pemohon"}>
                Permohonan Baru
              </CDropdownItem>
              {/* <CDropdownItem to={"/cari-berkas"}>Cari Berkas</CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
          <CNavLink to={"/simulasi-biaya-layanan"} className="font-weight-bold">
            <CIcon name="cil-calculator" className="mr-1" />
            Hitung Biaya (Kalkulator)
          </CNavLink>
        </CNavbarNav>
        <CNavbarNav className="ml-auto">
          <CNavLink to={"/login-petugas"} className="font-weight-bold">
            Login Petugas
          </CNavLink>
        </CNavbarNav>
      </CCollapse>
    </CNavbar>
  );
};

export default TheHeaderGuest;
