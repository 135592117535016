import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import {
  loadingBar,
  setFileOutputChekplots,
  setFileOutputHasilAkhirs,
  setFileOutputLegalisasiGus,
  setFileOutputPengolahans,
  setFileOutputPengukurans,
  setFileSyaratPermohonan,
} from "../globalAction";
import {
  setDataComboDesa,
  setDataComboKab,
  setDataComboKec,
  setDataComboKjsbByProv,
  setDataComboProv,
  setDataComboSurveyor,
} from "../comboAction";

export const setFormSemuaPermohonan = (formType, formValue) => {
  return { type: "FORM_SEMUA_PERMOHONAN", formType, formValue };
};

export const setDataSemuaPermohonan = (idPermohonan) => {
  loadingBar(true);
  return (dispatch) => {
    dispatch({ type: "RESET_FORM_PERMOHONAN" });
    dispatch({ type: "FILE_SYARAT_PERMOHONAN", payload: [] });
    dispatch({ type: "BERKAS_PERMOHONAN", payload: [] });
    dispatch({
      type: "DATA_PELAYANAN_PREVIEW_BERKAS",
      payload: null,
    });
    http
      .get(`${URLSVC}/Layanan/GetPermohonan?idPermohonan=${idPermohonan}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(
            setFormSemuaPermohonan("IdPermohonan", data.Data.IdPermohonan)
          );
          dispatch(
            setFormSemuaPermohonan("NoPermohonan", data.Data.NoPermohonan)
          );
          dispatch(setFormSemuaPermohonan("Nama", data.Data.Nama));
          dispatch(setFormSemuaPermohonan("NoKtp", data.Data.NoKtp));
          dispatch(setFormSemuaPermohonan("Alamat", data.Data.Alamat));
          dispatch(setFormSemuaPermohonan("NoHp", data.Data.NoHp));
          dispatch(setFormSemuaPermohonan("Email", data.Data.Email));
          dispatch(
            setFormSemuaPermohonan("CurrentCatatan", data.Data.CurrentCatatan)
          );
          dispatch(
            setFormSemuaPermohonan(
              "AlamatBidangTanah",
              data.Data.AlamatBidangTanah
            )
          );
          dispatch(setFormSemuaPermohonan("LuasTanah", data.Data.LuasTanah));
          dispatch(
            setFormSemuaPermohonan(
              "JarakLokasiBidangTanah",
              data.Data.JarakLokasiBidangTanah
            )
          );
          dispatch(
            setFormSemuaPermohonan(
              "JumlahBidangSebelum",
              data.Data.JumlahBidangSebelum || ""
            )
          );
          dispatch(
            setFormSemuaPermohonan(
              "JumlahBidangSetelah",
              data.Data.JumlahBidangSetelah || ""
            )
          );
          dispatch(
            setFormSemuaPermohonan("Latitude", data.Data.Latitude || "")
          );
          dispatch(
            setFormSemuaPermohonan("Longitude", data.Data.Longitude || "")
          );

          const SELECT = {
            IdJenisPermohonan: {
              value: data.Data.JenisPermohonan.Id,
              label: data.Data.JenisPermohonan.Nama,
            },
            IdKjsb: {
              value: data.Data.Kjsb.Id,
              label: data.Data.Kjsb.Nama,
            },
            IdSurveyor: {
              value: data.Data.Surveyor?.Id,
              label: data.Data.Surveyor?.Nama,
            },
            IdWilker: {
              value: data.Data.Wilker.Id,
              label: data.Data.Wilker.Nama,
            },
            IdKabKot: {
              value: data.Data.Kabupaten.Id,
              label: data.Data.Kabupaten.Nama,
            },
            IdKec: {
              value: data.Data.Kecamatan.Id,
              label: data.Data.Kecamatan.Nama,
            },
            IdDesa: {
              value: data.Data.Desa.Id,
              label: data.Data.Desa.Nama,
            },
            IsDiwakilkan: {
              value: data.Data.IsDiwakilkan,
              label: data.Data.IsDiwakilkan ? "YA" : "TIDAK",
            },
          };

          dispatch(setFormSemuaPermohonan("NamaKjskb", SELECT.IdKjsb.label));
          dispatch(
            setFormSemuaPermohonan(
              "IdJenisPermohonan",
              SELECT.IdJenisPermohonan
            )
          );
          dispatch(setFormSemuaPermohonan("IdKjsb", SELECT.IdKjsb));
          dispatch(setFormSemuaPermohonan("IdSurveyor", SELECT.IdSurveyor));
          dispatch(setFormSemuaPermohonan("IdWilker", SELECT.IdWilker));
          dispatch(setFormSemuaPermohonan("IsDiwakilkan", SELECT.IsDiwakilkan));

          dispatch(setDataComboProv("DATA_COMBO_PROV"));
          dispatch(setDataComboKab("DATA_COMBO_KAB", SELECT.IdWilker.value));
          dispatch(setDataComboKec("DATA_COMBO_KEC", SELECT.IdKabKot.value));
          dispatch(setDataComboDesa("DATA_COMBO_DESA", SELECT.IdKec.value));
          dispatch(
            setDataComboKjsbByProv("DATA_COMBO_KJSB", SELECT.IdWilker.value)
          );
          dispatch(
            setDataComboSurveyor("DATA_COMBO_SURVEYOR", SELECT.IdKjsb.value)
          );

          dispatch(setFormSemuaPermohonan("IdProv", SELECT.IdWilker));
          dispatch(setFormSemuaPermohonan("IdKabKot", SELECT.IdKabKot));
          dispatch(setFormSemuaPermohonan("IdKec", SELECT.IdKec));
          dispatch(setFormSemuaPermohonan("IdDesa", SELECT.IdDesa));

          dispatch(
            setFileSyaratPermohonan(
              "FILE_SEMUA_PERMOHONAN_SYARAT",
              data.Data.JenisPermohonan.Id
            )
          );
          dispatch(
            setFileOutputChekplots(
              "FILE_SEMUA_PERMOHONAN_HASIL_CHECKPLOT",
              data.Data.JenisPermohonan.Id
            )
          );
          dispatch(
            setFileOutputPengukurans(
              "FILE_SEMUA_PERMOHONAN_PENGUKURAN",
              data.Data.JenisPermohonan.Id
            )
          );
          dispatch(
            setFileOutputPengolahans(
              "FILE_SEMUA_PERMOHONAN_HASIL_PENGOLAHAN",
              data.Data.JenisPermohonan.Id
            )
          );
          //   dispatch(
          //     setFileOutputLegalisasiGus(
          //       "FILE_SEMUA_PERMOHONAN_HASIL_LEGALISASI_GU",
          //       data.Data.JenisPermohonan.Id
          //     )
          //   ); belum terpakai
          dispatch(
            setFileOutputHasilAkhirs(
              "FILE_SEMUA_PERMOHONAN_HASIL_AKHIR",
              data.Data.JenisPermohonan.Id
            )
          );
          if (data.Data.JenisPermohonan.Kode === "pecahgabung") {
            dispatch(setFormSemuaPermohonan("IsInputJumlahBidang", true));
          } else {
            dispatch(setFormSemuaPermohonan("IsInputJumlahBidang", false));
          }

          dispatch({
            type: "DATA_PELAYANAN_PREVIEW_BERKAS",
            payload: data.Data,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_SYARAT",
            payload: data.Data.FileSyarats,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_LAINNYA",
            payload: data.Data.FileLainnyas,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_CHECKPLOT",
            payload: data.Data.FileHasilCheckplots,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_PENGUKURAN",
            payload: data.Data.FilePengukurans,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_PENGOLAHAN",
            payload: data.Data.FileHasilPengolahans,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_LEGALISASI_GU",
            payload: data.Data.FileLegalisasiGus,
          });
          dispatch({
            type: "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_AKHIR",
            payload: data.Data.FileHasilAkhirs,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiEditSemuaPermohonan = (iData, history) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IsDiwakilkan", iData.IsDiwakilkan.value);
    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("IdJenisPermohonan", iData.IdJenisPermohonan.value);
    fd.append("IdKjsb", iData.IdKjsb.value);
    fd.append("IdSurveyor", iData.IdSurveyor.value);
    fd.append("IdDesa", iData.IdDesa.value);
    fd.append("Nama", iData.Nama.toUpperCase());
    fd.append("NoKtp", iData.NoKtp);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("AlamatBidangTanah", iData.AlamatBidangTanah);
    fd.append("LuasTanah", iData.LuasTanah);
    fd.append("JarakLokasiBidangTanah", iData.JarakLokasiBidangTanah);
    fd.append("Latitude", iData.Latitude || "");
    fd.append("Longitude", iData.Longitude || "");
    fd.append("JumlahBidangSebelum", iData.JumlahBidangSebelum || "");
    fd.append("JumlahBidangSetelah", iData.JumlahBidangSetelah || "");

    var iv = 0;
    iData.FileSyarats?.map((v) => {
      if (v !== undefined) {
        fd.append(`FileSyarats[${iv}].IdFile`, v.IdFile);
        fd.append(`FileSyarats[${iv}].FileUpload`, v.File);
        iv++;
      }
    });
    iv = 0;
    iData.FileLainnyas?.map((v) => {
      if (v !== undefined) {
        fd.append(`FileLainnyas[${iv}].NamaFile`, v.Nama);
        fd.append(`FileLainnyas[${iv}].FileUpload`, v.File);
        iv++;
      }
    });
    iv = 0;
    iData.HasilCheckplotFiles?.map((v) => {
      if (v !== undefined) {
        fd.append(`HasilCheckplotFiles[${iv}].IdPermohonanOutput`, v.IdFile);
        fd.append(`HasilCheckplotFiles[${iv}].FileUpload`, v.File);
        iv++;
      }
    });
    iv = 0;
    iData.PengukuranFiles?.map((v) => {
      if (v !== undefined) {
        fd.append(`PengukuranFiles[${iv}].IdPermohonanOutput`, v.IdFile);
        fd.append(`PengukuranFiles[${iv}].FileUpload`, v.File);
        iv++;
      }
    });
    iv = 0;
    iData.HasilPengolahanFiles?.map((v) => {
      if (v !== undefined) {
        fd.append(`HasilPengolahanFiles[${iv}].IdPermohonanOutput`, v.IdFile);
        fd.append(`HasilPengolahanFiles[${iv}].FileUpload`, v.File);
        iv++;
      }
    });
    iv = 0;
    iData.HasilAkhirFiles?.map((v) => {
      if (v !== undefined) {
        fd.append(`HasilAkhirFiles[${iv}].IdPermohonanOutput`, v.IdFile);
        fd.append(`HasilAkhirFiles[${iv}].FileUpload`, v.File);
        iv++;
      }
    });

    http.post(`${URLSVC}/Layanan/EditPermohonan`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil memperbarui data`,
          "success"
        ).then(function () {
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiDeleteFileSemuaPermohonanDokumen = (
  idPermohonanDokumen,
  nama,
  idPermohonan
) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus file dokumen (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .post(
            `${URLSVC}/Layanan/DeleteFilePermohonanDokumen?idPermohonanDokumen=${idPermohonanDokumen}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil hapus file dokumen (${nama})`,
                "success"
              ).then(function () {
                dispatch(setDataSemuaPermohonan(idPermohonan));
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
