const initialState = {
  dataPengolahans: [],
  modalPengolahan: false,
  formPengolahan: {
    IdPermohonan: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
    JenisPermohonan: "",
    AlamatBidangTanah: "",
    JarakLokasiBidangTanah: "",
    LuasTanah: "",
    Latitude: "",
    Longitude: "",
    Wilker: {},
    Kjsb: "",
  },
  fileSyaratPengolahan: [],

};

const pengolahanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PENGOLAHANS":
      return { ...state, dataPengolahans: action.payload };
    case "FORM_PENGOLAHAN":
      return {
        ...state,
        formPengolahan: {
          ...state.formPengolahan,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PENGOLAHAN":
      return {
        ...state,
        formPengolahan: initialState.formPengolahan,
      };
    case "MODAL_PENGOLAHAN":
      return { ...state, modalPengolahan: action.payload };
    case "FILE_SYARAT_PENGOLAHAN":
        return { ...state, fileSyaratPengolahan: action.payload };
    default:
      return state;
  }
};

export default pengolahanReducer;
