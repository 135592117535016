import React, { useEffect } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable, ReactDatePicker, ReactSelect } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  apiKonfirmasiJadwal,
  setDataBiayaJadwalPermohonan,
  setDataPermohonans,
} from "src/config/redux/action";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";

const KonfirmasiJadwal = () => {
  const {
    dataBiayaJadwalPermohonans,
    modalBiayaJadwalPermohonan,
    formBiayaJadwalPermohonan,
  } = useSelector((state) => state.hitungBiayaDanPenjadwalanReducer);
  const dispatch = useDispatch();

  const {
    NoPermohonan,
    Nama,
    Email,
    JadwalUkur,
    StatusProses,
    JamUkurAwal,
    JamUkurAkhir,
  } = formBiayaJadwalPermohonan;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("NoPermohonan", NoPermohonan);
    setValue("Nama", Nama);
    setValue("Email", Email);
    setValue("JadwalUkur", JadwalUkur);
    setValue("JamUkurAwal", JamUkurAwal);
    setValue("JamUkurAkhir", JamUkurAkhir);
    setValue("StatusProses", StatusProses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    NoPermohonan,
    Nama,
    Email,
    JadwalUkur,
    JamUkurAwal,
    JamUkurAkhir,
    StatusProses,
  ]);

  useEffect(() => {
    dispatch(
      setDataPermohonans("DATA_BIAYA_JADWAL_PERMOHONAN", "konfirmasi-jadwal")
    );
    dispatch({
      type: "MODAL_BIAYA_JADWAL_PERMOHONAN",
      payload: false,
    });
  }, [dispatch]);

  const handleModal = (idPermohonan) => {
    reset();
    setValue("IdPermohonan", idPermohonan);
    dispatch(setDataBiayaJadwalPermohonan(idPermohonan));
  };

  const showModal = () =>
    dispatch({
      type: "MODAL_BIAYA_JADWAL_PERMOHONAN",
      payload: !modalBiayaJadwalPermohonan,
    });

  const btnSetujui = (data) => dispatch(apiKonfirmasiJadwal(data));

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 130,
        minWidth: 130,
      },
      headerStyle: {
        width: 130,
        minWidth: 130,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleModal(rowData.IdPermohonan)}
            >
              <CIcon name="cil-check" /> SETUJUI
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          <span className="badge badge-warning">
            {rowData.StatusProses || "-"}
          </span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  const dataJsonStatusProses = [
    {
      value: "1",
      label: "SEPAKAT",
    },
    {
      value: "-1",
      label: "Tidak Sepakat",
    },
  ];
  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataBiayaJadwalPermohonans}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>

      <CModal show={modalBiayaJadwalPermohonan} onClose={showModal}>
        <CModalHeader closeButton>DETAIL PERMOHONAN</CModalHeader>
        <form id="form" onSubmit={handleSubmit(btnSetujui)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>JADWAL UKUR</label>
                  <Controller
                    control={control}
                    // defaultValue={new Date()}
                    name="JadwalUkur"
                    render={({ field, onChange, value }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Ukur..."
                        selected={field.value}
                        value={getValues("JadwalUkur")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        // readOnly={isAgreed}
                        readOnly={getValues("StatusProses")?.value === "1"}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.JadwalUkur && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>STATUS PROSES</label>
                  <Controller
                    control={control}
                    defaultValue={StatusProses}
                    name="StatusProses"
                    render={({ field, value, name, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonStatusProses}
                        value={getValues("StatusProses")}
                        onChange={(val) => {
                          field.onChange(val);
                          setValue("StatusProses", val, {
                            shouldValidate: true,
                          });
                          if (val.value === "1") {
                            setValue("JadwalUkur", JadwalUkur);
                          }
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.StatusProses && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>CATATAN</label>
                  <textarea
                    name="Catatan"
                    rows={5}
                    className="form-control"
                    placeholder="Ketikan Catatan..."
                    {...register("Catatan", {
                      required: false,
                    })}
                  />
                  {errors.Catatan && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              SETUJUI
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </>
  );
};

export default KonfirmasiJadwal;
