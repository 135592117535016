import React from "react";
import { CCard, CCardHeader } from "@coreui/react";
import { apiEditPermohonan } from "src/config/redux/action";
import FormAddEdit from "./parts/FormAddEdit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const PermohonanEdit = ({ match }) => {
  var PARAMId = match.params.id;

  const history = useHistory();
  const dispatch = useDispatch();
  const btnEdit = (data) => dispatch(apiEditPermohonan(data, history));
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <CCard>
            <CCardHeader>FORM PERBARUI PERMOHONAN</CCardHeader>
            <FormAddEdit save={btnEdit} id={PARAMId} />
          </CCard>
        </div>
      </div>
    </div>
  );
};

export default PermohonanEdit;
