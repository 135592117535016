import React, { useEffect } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CCardHeader,
  CCardBody,
  CCardFooter,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import {
  apiVerifikasiBerkasPermohonan,
  apiVerifikasiTolakBerkasPermohonan,
  setDataPermohonan,
  setFormPermohonan,
} from "src/config/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";

const PermohonanValidasi = ({ match }) => {
  var PARAMId = match.params.id;
  const history = useHistory();
  const { formPermohonan, modalPermohonanValidasi } = useSelector(
    (state) => state.permohonanReducer
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    dispatch(setDataPermohonan(PARAMId));
  }, [dispatch]);

  const btnValidate = () => {
    dispatch(apiVerifikasiBerkasPermohonan(PARAMId, history));
  };
  const btnReject = () => {
    dispatch(
      apiVerifikasiTolakBerkasPermohonan(
        PARAMId,
        formPermohonan.Catatan,
        history
      )
    );
  };
  const showModalReject = () =>
    dispatch({
      type: "MODAL_PERMOHONAN_VALIDASI",
      payload: !modalPermohonanValidasi,
    });

  return (
    <>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <CCard>
            <CCardHeader>VALIDASI PERMOHONAN</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>No. Permohonan</td>
                    <td>
                      : <code>#{formPermohonan.NoPermohonan}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Nama Pemohon</td>
                    <td>
                      : <b>{formPermohonan.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. KTP</td>
                    <td>
                      : <b>{formPermohonan.NoKtp}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>
                      : <b>{formPermohonan.Alamat}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. HP</td>
                    <td>
                      : <b>{formPermohonan.NoHp}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      : <b>{formPermohonan.Email}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>KJSB</td>
                    <td>
                      :{" "}
                      <span className="text-primary">
                        {formPermohonan.NamaKjskb}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Bidang Tanah</td>
                    <td>
                      : <b>{formPermohonan.AlamatBidangTanah}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Luas Tanah</td>
                    <td>
                      :{" "}
                      <b>
                        {formPermohonan.LuasTanah.toLocaleString("id-ID")}{" "}
                        M&sup2;
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Jarak Lokasi Bidang Tanah</td>
                    <td>
                      :{" "}
                      <b>
                        {formPermohonan.JarakLokasiBidangTanah.toLocaleString(
                          "id-ID"
                        )}{" "}
                        KM
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Lokasi</td>
                    <td>
                      :{" "}
                      {formPermohonan.Latitude && (
                        <iframe
                          src={`https://maps.google.com/maps?q=${formPermohonan.Latitude}, ${formPermohonan.Longitude}&z=15&output=embed`}
                          width="300"
                          height="250"
                          title="Lokasi"
                          frameborder="0"
                          style={{ border: 0 }}
                        ></iframe>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
            <CCardFooter align="right">
              <CButton color="danger" onClick={() => showModalReject()}>
                TOLAK
              </CButton>
              <CButton
                color="success"
                onClick={() => btnValidate()}
                className="ml-2"
              >
                TERIMA
              </CButton>
            </CCardFooter>
          </CCard>
        </div>
      </div>
      <CModal show={modalPermohonanValidasi} onClose={showModalReject}>
        <CModalHeader closeButton>TOLAK VALIDASI</CModalHeader>
        <CModalBody>
          <div className="row">
            <div className="col-md-12">
              <form id="form">
                <div className="form-group">
                  <label>CATATAN</label>
                  <textarea
                    onChange={(e) =>
                      dispatch(setFormPermohonan("Catatan", e.target.value))
                    }
                    className="form-control"
                  />
                </div>
              </form>
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={btnReject}>
            LANJUTKAN TOLAK
          </CButton>{" "}
          <CButton color="secondary" onClick={showModalReject}>
            BATAL
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default PermohonanValidasi;
