const initialState = {
  dataGooglePlaces: [],
  dataGooglePlaceById: false,
};

const googleMapPlaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_GOOGLE_PLACES":
      return { ...state, dataGooglePlaces: action.payload };
    case "DATA_GOOGLE_PLACE_BY_ID":
      return { ...state, dataGooglePlaceById: action.payload };
    default:
      return state;
  }
};

export default googleMapPlaceReducer;
