import React from "react";
import { CCard, CCardHeader } from "@coreui/react";
import { apiAddPermohonan } from "src/config/redux/action";
import FormAddEdit from "./parts/FormAddEdit";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const PermohonanAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const btnAdd = (data) => dispatch(apiAddPermohonan(data, null, history));
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <CCard>
            <CCardHeader>FORM TAMBAH PERMOHONAN</CCardHeader>
            <FormAddEdit save={btnAdd} />
          </CCard>
        </div>
      </div>
    </div>
  );
};

export default PermohonanAdd;
