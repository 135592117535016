const initialState = {
  dataSemuaPermohonans: [],
  formSemuaPermohonan: {
    IdPermohonan: "",
    IsDiwakilkan: "",
    IdJenisPermohonan: "",
    IdJenisSertipikat: "",
    IdKjsb: "",
    IdSurveyor: "",
    IdProv: "",
    IdKabKot: "",
    IdKec: "",
    IdDesa: "",
    NoPermohonan: "",
    NoSertipikat: "",
    Nama: "",
    NamaKjskb: "",
    NoKtp: "",
    Alamat: "",
    NoHp: "",
    Email: "",
    AlamatBidangTanah: "",
    LuasTanah: "",
    JarakLokasiBidangTanah: "",
    Files: "",
    JumlahBidangSebelum: "",
    JumlahBidangSetelah: "",
    Latitude: "",
    Longitude: "",

    IsInputJumlahBidang: false,
    Catatan: "",
    RpChekplot: "",
    CurrentCatatan: "",
  },
  dataBerkasSemuaPermohonanSyarat: [],
  dataBerkasSemuaPermohonanLainnya: [],
  dataBerkasSemuaPermohonanHasilCheckplot: [],
  dataBerkasSemuaPermohonanPengukuran: [],
  dataBerkasSemuaPermohonanHasilPengolahan: [],
  dataBerkasSemuaPermohonanHasilLegalisasiGu: [],
  dataBerkasSemuaPermohonanHasilAkhir: [],
  modalPencarianAlamat: false,
  modalMapBidangTanah: false,
  fileSemuaPermohonanSyarat: [],
  fileSemuaPermohonanLainnya: [],
  fileSemuaPermohonanHasilCheckplot: [],
  fileSemuaPermohonanPengukuran: [],
  fileSemuaPermohonanHasilPengolahan: [],
  fileSemuaPermohonanHasilLegalisasiGu: [],
  fileSemuaPermohonanHasilAkhir: [],
};

const semuaPermohonanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_SEMUA_PERMOHONANS":
      return { ...state, dataSemuaPermohonans: action.payload };
    case "FORM_SEMUA_PERMOHONAN":
      return {
        ...state,
        formSemuaPermohonan: {
          ...state.formSemuaPermohonan,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_SEMUA_PERMOHONAN":
      return {
        ...state,
        formSemuaPermohonan: initialState.formSemuaPermohonan,
      };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_SYARAT":
      return { ...state, dataBerkasSemuaPermohonanSyarat: action.payload };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_LAINNYA":
      return { ...state, dataBerkasSemuaPermohonanLainnya: action.payload };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_CHECKPLOT":
      return {
        ...state,
        dataBerkasSemuaPermohonanHasilCheckplot: action.payload,
      };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_PENGUKURAN":
      return {
        ...state,
        dataBerkasSemuaPermohonanPengukuran: action.payload,
      };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_PENGOLAHAN":
      return {
        ...state,
        dataBerkasSemuaPermohonanHasilPengolahan: action.payload,
      };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_LEGALISASI_GU":
      return {
        ...state,
        dataBerkasSemuaPermohonanHasilLegalisasiGu: action.payload,
      };
    case "DATA_BERKAS_SEMUA_PERMOHONAN_HASIL_AKHIR":
      return {
        ...state,
        dataBerkasSemuaPermohonanHasilAkhir: action.payload,
      };

    case "FILE_SEMUA_PERMOHONAN_SYARAT":
      return { ...state, fileSemuaPermohonanSyarat: action.payload };
    case "FILE_SEMUA_PERMOHONAN_LAINNYA":
      return { ...state, fileSemuaPermohonanLainnya: action.payload };
    case "FILE_SEMUA_PERMOHONAN_HASIL_CHECKPLOT":
      return {
        ...state,
        fileSemuaPermohonanHasilCheckplot: action.payload,
      };
    case "FILE_SEMUA_PERMOHONAN_PENGUKURAN":
      return {
        ...state,
        fileSemuaPermohonanPengukuran: action.payload,
      };
    case "FILE_SEMUA_PERMOHONAN_HASIL_PENGOLAHAN":
      return {
        ...state,
        fileSemuaPermohonanHasilPengolahan: action.payload,
      };
    case "FILE_SEMUA_PERMOHONAN_HASIL_LEGALISASI_GU":
      return {
        ...state,
        fileSemuaPermohonanHasilLegalisasiGu: action.payload,
      };
    case "FILE_SEMUA_PERMOHONAN_HASIL_AKHIR":
      return {
        ...state,
        fileSemuaPermohonanHasilAkhir: action.payload,
      };
    case "MODAL_SEMUA_PERMOHONAN_PENCARIAN_ALAMAT":
      return { ...state, modalPencarianAlamat: action.payload };
    case "MODAL_SEMUA_PERMOHONAN_MAP_BIDANG_TANAH":
      return { ...state, modalMapBidangTanah: action.payload };
    default:
      return state;
  }
};

export default semuaPermohonanReducer;
