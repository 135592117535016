import React, { useEffect } from "react";
import {
  CCard,
  CButton,
  CCardHeader,
  CCardBody,
  CCardFooter,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import {
  setDataHasilAkhir,
  apiInputHasilAkhirx,
} from "src/config/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import CIcon from "@coreui/icons-react";

const HasilAkhirUpload = ({ match }) => {
  var PARAMId = match.params.id;
  const history = useHistory();
  const { formHasilAkhir, fileSyaratHasilAkhir } = useSelector(
    (state) => state.hasilAkhirReducer
  );
  const dispatch = useDispatch();

  const {
    NoPermohonan,
    Nama,
    Email,
    JenisPermohonan,
    AlamatBidangTanah,
    JarakLokasiBidangTanah,
    LuasTanah,
    Wilker,
    Kjsb,
  } = formHasilAkhir;

  const { handleSubmit, setValue } = useForm();

  useEffect(() => {
    dispatch(setDataHasilAkhir(PARAMId));
    setValue("IdPermohonan", PARAMId);
  }, [dispatch]);

  const btnHasilAkhir = (data) => dispatch(apiInputHasilAkhirx(data, history));

  const onFile = (idFile, e) => {
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target);
    const file = e.target.files[0];
    fileSyaratHasilAkhir.map((v) => {
      if (v.IdPermohonanOutput === idFile) {
        v.IdFile = idFile;
        v.FileUpload = file;
        delete v.Kode;
        delete v.NamaSingkat;
      }
    });
    dispatch({
      type: "FILE_SYARAT_HASIL_AKHIR",
      payload: fileSyaratHasilAkhir,
    });
    setValue("Files", fileSyaratHasilAkhir);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <CCard>
            <CCardHeader>DATA PEMOHON</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>No. Permohonan</td>
                    <td>
                      : <code>#{NoPermohonan}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>
                      : <b>{Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      : <b>{Email}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>PERMINTAAN</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>Jenis Permohonan</td>
                    <td>
                      : <b>{JenisPermohonan}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Bidang Tanah</td>
                    <td>
                      : <b>{AlamatBidangTanah}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Jarak Lokasi</td>
                    <td>
                      :{" "}
                      <b>{JarakLokasiBidangTanah.toLocaleString("id-ID")} KM</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Luas Tanah</td>
                    <td>
                      : <b>{LuasTanah.toLocaleString("id-ID")} M&sup2;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Wilayah Kerja</td>
                    <td>
                      : <b>{Wilker.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>KJSB</td>
                    <td>
                      : <b>{Kjsb}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div className="col-md-7">
          <CCard>
            <CCardHeader>FORM HASIL AKHIR</CCardHeader>
            <form onSubmit={handleSubmit(btnHasilAkhir)}>
              <CCardBody>
                <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-primary">
                      <b>Info Upload File</b> <br />
                      1. Ukuran file maksimal 2MB <br />
                      2. Format file yang digunakan wajib .pdf .png .jpg
                    </div>
                    {fileSyaratHasilAkhir.map((v, i) => {
                      var randName = `file${i + Date.now()}`;
                      var fileOld = formHasilAkhir?.FileHasilAkhirs?.filter(
                        (x) => x.IdFile === v.IdPermohonanOutput
                      )[0];
                      return v.value !== "" ? (
                        <div key={i} className="form-group">
                          <label>{v.Nama.toUpperCase()}</label>
                          <input
                            type="file"
                            className="form-control"
                            id={`${randName}`}
                            name={`${randName}`}
                            onChange={(e) => onFile(v.IdPermohonanOutput, e)}
                          />
                          {fileOld && (
                            <small>
                              <a
                                href={`${fileOld?.PathUrl}/${fileOld?.NamaFile}`}
                                target="_blank"
                                className="text-info"
                                rel="noreferrer"
                              >
                                Lihat File{" "}
                                <CIcon size={"xs"} name={"cilExternalLink"} />
                              </a>
                            </small>
                          )}
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </CCardBody>
              <CCardFooter align="right">
                <CButton color="secondary" onClick={() => history.goBack()}>
                  BATAL
                </CButton>
                <CButton color="primary" type="submit" className="ml-2">
                  SIMPAN
                </CButton>
              </CCardFooter>
            </form>
          </CCard>
        </div>
      </div>
    </>
  );
};

export default HasilAkhirUpload;
