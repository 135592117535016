import React, { useEffect } from "react";
import {
  CCard,
  CButton,
  CCardHeader,
  CCardBody,
  CCardFooter,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import {
  apiProsesPengukuran,
  setDataPengukuran,
} from "src/config/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { ReactDatePicker } from "src/components";
import ReactInputMask from "react-input-mask";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";

const PengukuranSet = ({ match }) => {
  var PARAMId = match.params.id;
  const history = useHistory();
  const { formPengukuran, fileSyaratPengukuran } = useSelector(
    (state) => state.pengukuranReducer
  );
  const dispatch = useDispatch();

  const {
    NoPermohonan,
    Nama,
    Email,
    JenisPermohonan,
    AlamatBidangTanah,
    JarakLokasiBidangTanah,
    LuasTanah,
    Wilker,
    Kjsb,
  } = formPengukuran;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    dispatch(setDataPengukuran(PARAMId));
    setValue("IdPermohonan", PARAMId);
  }, [dispatch]);

  useEffect(() => {
    setValue("TanggalUkur", formPengukuran?.JadwalUkur);
    setValue("Catatan", formPengukuran?.Catatan);
  }, [formPengukuran]);

  const btnProsesPengukuran = (data) =>
    dispatch(apiProsesPengukuran(data, history));

  const onFile = (idPermohonanOutput, e) => {
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target, [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".zip",
      ".rar",
    ]);
    const file = e.target.files[0];
    fileSyaratPengukuran.map((v) => {
      if (v.IdPermohonanOutput === idPermohonanOutput) {
        v.IdFile = idPermohonanOutput;
        v.FileUpload = file;
        delete v.Kode;
        delete v.NamaSingkat;
      }
    });
    dispatch({
      type: "FILE_SYARAT_PENGUKURAN",
      payload: fileSyaratPengukuran,
    });
    setValue("Files", fileSyaratPengukuran);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <CCard>
            <CCardHeader>DATA PEMOHON</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>No. Permohonan</td>
                    <td>
                      : <code>#{NoPermohonan}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>
                      : <b>{Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      : <b>{Email}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>PERMINTAAN</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>Jenis Permohonan</td>
                    <td>
                      : <b>{JenisPermohonan}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Bidang Tanah</td>
                    <td>
                      : <b>{AlamatBidangTanah}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Jarak Lokasi</td>
                    <td>
                      :{" "}
                      <b>{JarakLokasiBidangTanah.toLocaleString("id-ID")} KM</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Luas Tanah</td>
                    <td>
                      : <b>{LuasTanah.toLocaleString("id-ID")} M&sup2;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Wilayah Kerja</td>
                    <td>
                      : <b>{Wilker.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>KJSB</td>
                    <td>
                      : <b>{Kjsb}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div className="col-md-7">
          <CCard>
            <CCardHeader>FORM PENGUKURAN</CCardHeader>
            <form onSubmit={handleSubmit(btnProsesPengukuran)}>
              <CCardBody>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>TANGGAL UKUR</label>
                      <Controller
                        control={control}
                        defaultValue={getValues("TanggalUkur")}
                        name="TanggalUkur"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Ketikan Jadwal Ukur..."
                            selected={field.value}
                            value={getValues("TanggalUkur")}
                            onChange={(val) => field.onChange(val)}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                              <ReactInputMask
                                mask="99-99-9999"
                                onChange={field.onChange}
                                value={field.value}
                                maskChar=""
                              />
                            }
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.TanggalUkur && (
                        <span className="text-danger">Kolom wajib diisi</span>
                      )}
                    </div>
                    {/* <div className="form-group">
                      <label>JAM UKUR</label>
                      <input
                        type="time"
                        name="JamUkur"
                        className="form-control"
                        placeholder="Ketikan Jam Ukur..."
                        {...register("JamUkur", {
                          required: "Kolom wajib diisi",
                        })}
                      />
                      {errors.JamUkur && (
                        <span className="text-danger">
                          {errors.JamUkur?.message}
                        </span>
                      )}
                    </div> */}
                    <div className="form-group">
                      <label>CATATAN</label>
                      <textarea
                        name="Catatan"
                        rows={5}
                        className="form-control"
                        placeholder="Ketikan Catatan..."
                        {...register("Catatan", {
                          required: false,
                        })}
                      />
                      {errors.Catatan && (
                        <span className="text-danger">Kolom wajib diisi</span>
                      )}
                    </div>
                    <div className="alert alert-primary">
                      <b>Info Upload File</b> <br />
                      1. Ukuran file maksimal 2MB <br />
                      2. Format file yang digunakan wajib .pdf .png .jpg
                    </div>
                    {fileSyaratPengukuran.map((v, i) => {
                      console.log("aa", v.value);
                      var randName = `file${i + Date.now()}`;
                      return v.value !== "" ? (
                        <div key={i} className="form-group">
                          <label>{v.Nama.toUpperCase()}</label>
                          <input
                            type="file"
                            className="form-control"
                            id={`${randName}`}
                            name={`${randName}`}
                            onChange={(e) => onFile(v.IdPermohonanOutput, e)}
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </CCardBody>
              <CCardFooter align="right">
                <CButton color="secondary" onClick={() => history.goBack()}>
                  BATAL
                </CButton>
                <CButton color="primary" type="submit" className="ml-2">
                  SIMPAN
                </CButton>
              </CCardFooter>
            </form>
          </CCard>
        </div>
      </div>
    </>
  );
};

export default PengukuranSet;
