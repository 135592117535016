import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import {
  FUNCArraySelectKode,
  FUNCArraySelectId,
  FUNCArraySelectRole,
  http,
} from "src/config";
import axios from "axios";

export const setDataComboProv = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Wil/GetProvinsis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboProvByGuest = (type) => {
  return (dispatch) => {
    axios.get(`${URLSVC}/Web/GetProvinsis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKab = (type, idProv) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Wil/GetKabupatens?idProvinsi=${idProv}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKabByGuest = (type, idProv) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Web/GetKabupatens?idProvinsi=${idProv}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKec = (type, KdKab) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Wil/GetKecamatans?idKabupaten=${KdKab}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
export const setDataComboKecByGuest = (type, KdKab) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Web/GetKecamatans?idKabupaten=${KdKab}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboDesa = (type, KdKec) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Wil/GetDesas?idKecamatan=${KdKec}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboDesaByGuest = (type, KdKec) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Web/GetDesas?idKecamatan=${KdKec}`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboJenisSurveyors = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Member/GetJenisSurveyors`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboRole = (type = "DATA_COMBO_ROLE") => {
  return (dispatch) => {
    http.get(`${URLSVC}/User/GetRoles`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectRole(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKelPermohonan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Layanan/GetKelPermononans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboJenisPermohonan = (type, idKelPermohonan) => {
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Layanan/GetJenisPermohonans?idKelPermohonan=${idKelPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboJenisPermohonanByGuest = (type, idKelPermohonan) => {
  return (dispatch) => {
    axios
      .get(
        `${URLSVC}/Web/GetJenisPermohonans?idKelPermohonan=${idKelPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboSyaratPermohonan = (type, idJenisPermohonan) => {
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Layanan/GetSyaratPermohonans?idJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboBiayaJenisProperties = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Layanan/GetBiayaJenisProperties`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboBiayaJenisPropertiesByGuest = (type) => {
  return (dispatch) => {
    axios.get(`${URLSVC}/Web/GetBiayaJenisProperties`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKjsb = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Member/GetKjsbs`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        var array = [];
        array.push({
          value: "",
          label: "SELECT ONE",
        });
        data.Data.map((v, i) =>
          array.push({
            value: v.IdKjsb,
            label: `${v.Nama}  (${v.WilKer})`,
          })
        );
        dispatch({ type, payload: array });
      } else {
        // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error')
      }
    });
  };
};

export const setDataComboKjsbByProv = (type, idProv) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Member/GetKjsbsByProvinsi?idProvinsi=${idProv}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          // let array = FUNCArraySelectId(data.Data)
          var array = [];
          array.push({
            value: "",
            label: "SELECT ONE",
          });
          data.Data.map((v, i) =>
            array.push({
              value: v.IdKjsb,
              label: `${v.Nama}  (${v.WilKer})`,
            })
          );
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboKjsbByProvByGuest = (type, idProv) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Web/GetKjsbsByProvinsi?idProvinsi=${idProv}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          // let array = FUNCArraySelectId(data.Data)
          var array = [];
          array.push({
            value: "",
            label: "SELECT ONE",
          });
          data.Data.map((v, i) =>
            array.push({
              value: v.IdKjsb,
              label: `${v.Nama}  (${v.WilKer})`,
            })
          );
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboSurveyor = (type = "DATA_COMBO_SURVEYOR", idKjsb) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Member/GetSurveyorsByKjsb?idKjsb=${idKjsb}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          var array = [];
          data.Data.map((v, i) =>
            array.push({
              value: v.IdSurveyor,
              label: `${v.Nama} / ${v.NoLisensi}`,
              image: v.PasFoto,
            })
          );
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboKjsbByWilker = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Member/GetKjsbsByWilker`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        var array = [];
        array.push({
          value: "",
          label: "SELECT ONE",
        });
        data.Data.map((v, i) =>
          array.push({
            value: v.IdKjsb,
            label: `${v.Nama}  (${v.WilKer})`,
          })
        );
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
      }
    });
  };
};
