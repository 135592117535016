import React, { useEffect, useMemo, useRef } from "react";
import {
  CAlert,
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CCollapse,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import { DataTable, LeafletPopup, ReactSelect } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormPermohonan,
  setDataJenisPermohonan,
  setDataComboSyaratPermohonan,
  setFileSyaratPermohonan,
  setDataPermohonan,
  setDataComboProv,
  setDataComboKjsbByProv,
  setDataCekJenisPermohonan,
  setDataComboKab,
  setDataComboDesa,
  setDataComboKec,
  setDataComboSurveyor,
  setDataCekMemberExpiredSurveyor,
  apiDeleteFilePermohonanDokumen,
} from "src/config/redux/action";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { useHistory } from "react-router-dom";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { IMGNoPhoto } from "src/assets";
import CIcon from "@coreui/icons-react";
import { Marker, Popup } from "react-leaflet";
import { useState } from "react";

const FormAddEdit = ({ id = null, save }) => {
  const markerRef = useRef(null);
  const history = useHistory();
  const {
    formPermohonan,
    fileSyaratPermohonan,
    modalPencarianAlamat,
    modalMapBidangTanah,
    berkasPermohonanSyarats,
    berkasPermohonanLainnyas,
  } = useSelector((state) => state.permohonanReducer);
  const {
    dataComboJenisPermohonan,
    dataComboKjsb,
    dataComboSurveyor,
    dataComboProv,
    dataComboKab,
    dataComboKec,
    dataComboDesa,
  } = useSelector((state) => state.comboReducer);

  const dispatch = useDispatch();

  const [collapseDokSyarat, setCollapseDokSyarat] = useState(id !== null);
  const [collapseDokLainnya, setCollapseDokLainnya] = useState(id !== null);

  const toggleDokSyarat = (e) => {
    setCollapseDokSyarat(!collapseDokSyarat);
    e.preventDefault();
  };

  const toggleDokLainnya = (e) => {
    setCollapseDokLainnya(!collapseDokLainnya);
    e.preventDefault();
  };

  const {
    IdJenisPermohonan,
    IsDiwakilkan,
    IdKjsb,
    IdSurveyor,
    IdProv,
    IdKabKot,
    IdKec,
    IdDesa,
    Nama,
    NoKtp,
    Alamat,
    NoHp,
    Email,
    AlamatBidangTanah,
    LuasTanah,
    JarakLokasiBidangTanah,
    JumlahBidangSebelum,
    JumlahBidangSetelah,
    IdPermohonan,
    Latitude,
    Longitude,
    IsInputJumlahBidang,
  } = formPermohonan;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "FileLainnyas",
  });

  const eventDragMap = useMemo(() => ({
    dragend() {
      const marker = markerRef.current;
      if (marker !== null) {
        setValue("Latitude", marker.getLatLng().lat, {
          shouldValidate: true,
        });
        setValue("Longitude", marker.getLatLng().lng, {
          shouldValidate: true,
        });
      }
    },
  }));

  useEffect(() => {
    setValue("IdPermohonan", IdPermohonan);
    setValue("IsDiwakilkan", IsDiwakilkan);
    setValue("IdJenisPermohonan", IdJenisPermohonan);
    setValue("IdKjsb", IdKjsb);
    setValue("IdSurveyor", IdSurveyor);
    setValue("IdProv", IdProv);
    setValue("IdKabKot", IdKabKot);
    setValue("IdKec", IdKec);
    setValue("IdDesa", IdDesa);
    setValue("Nama", Nama);
    setValue("NoKtp", NoKtp);
    setValue("Alamat", Alamat);
    setValue("NoHp", NoHp);
    setValue("Email", Email);
    setValue("LuasTanah", LuasTanah);
    setValue("AlamatBidangTanah", AlamatBidangTanah);
    setValue("JarakLokasiBidangTanah", JarakLokasiBidangTanah);
    setValue("JumlahBidangSebelum", JumlahBidangSebelum);
    setValue("JumlahBidangSetelah", JumlahBidangSetelah);
    setValue("FileSyarats", []);
    setValue("FileLainnyas", []);
    setValue("Latitude", Latitude);
    setValue("Longitude", Longitude);
    setValue("SearchAlamat", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IdPermohonan,
    IsDiwakilkan,
    IdJenisPermohonan,
    IdKjsb,
    IdSurveyor,
    IdProv,
    IdKabKot,
    IdKec,
    IdDesa,
    Nama,
    NoKtp,
    Alamat,
    NoHp,
    Email,
    AlamatBidangTanah,
    LuasTanah,
    JarakLokasiBidangTanah,
    JumlahBidangSebelum,
    JumlahBidangSetelah,
    Latitude,
    Longitude,
  ]);

  useEffect(() => {
    if (id === null) {
      dispatch({ type: "RESET_FORM_PERMOHONAN" });
      dispatch({ type: "FILE_SYARAT_PERMOHONAN", payload: [] });
      dispatch({ type: "DATA_COMBO_KJSB", payload: [] });
      dispatch({ type: "DATA_COMBO_SURVEYOR", payload: [] });
    } else {
      dispatch(setDataPermohonan(id));
    }
    dispatch(setDataJenisPermohonan("DATA_COMBO_JENIS_PERMOHONAN", 1));
    dispatch(setFormPermohonan("IdKelPermohonan", 1));
    dispatch(setDataComboProv("DATA_COMBO_PROV"));
  }, [dispatch]);

  const onTimKjsb = (e) => {
    dispatch({ type: "DATA_COMBO_KAB", payload: [] });
    dispatch({ type: "DATA_COMBO_KEC", payload: [] });
    dispatch({ type: "DATA_COMBO_DESA", payload: [] });
    dispatch({ type: "DATA_COMBO_KJSB", payload: [] });
    dispatch({ type: "DATA_COMBO_SURVEYOR", payload: [] });
    setValue("IdKabKot", "");
    setValue("IdKec", "");
    setValue("IdDesa", "");
    setValue("IdKjsb", "");
    setValue("IdSurveyor", "");

    dispatch(setDataComboKjsbByProv("DATA_COMBO_KJSB", e.value));
    dispatch(setDataComboKab("DATA_COMBO_KAB", e.value));
  };

  const onTimSb = (e) => {
    dispatch({ type: "DATA_COMBO_SURVEYOR", payload: [] });
    setValue("IdSurveyor", "");
    dispatch(setDataComboSurveyor("DATA_COMBO_SURVEYOR", e.value));
  };

  const onPermohonan = (kode, type) => {
    if (kode?.value !== "") {
      dispatch(setDataCekJenisPermohonan(kode?.value));
      switch (type) {
        case "JENIS_PERMOHONAN":
          // setValue("IdJenisPermohonan", kode);
          dispatch(
            setDataComboSyaratPermohonan(
              "DATA_COMBO_SYARAT_PERMOHONAN",
              kode?.value
            )
          );
          dispatch(
            setFileSyaratPermohonan("FILE_SYARAT_PERMOHONAN", kode.value)
          );
          if (kode.kode === "pecahgabung") {
            setValue("IsInputJumlahBidang", true);
          } else {
            setValue("IsInputJumlahBidang", false);
            setValue("JumlahBidangSebelum", "");
            setValue("JumlahBidangSetelah", "");
          }
          break;
        default:
          break;
      }
    }
  };

  const onCheckFileSyarat = (val, index, idFile) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target);
    setValue(`FileSyarats[${index}]`, {
      IdFile: idFile,
      File: val.target.files[0],
    });
  };
  const onCheckFileLainnya = (val, index) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target);
    setValue(`FileLainnyas[${index}].File`, val.target.files[0]);
  };

  const showModalPencarianAlamat = () => {
    dispatch({
      type: "MODAL_PENCARIAN_ALAMAT",
      payload: !modalPencarianAlamat,
    });
  };
  const showModalMapBidangTanah = () => {
    dispatch({
      type: "MODAL_MAP_BIDANG_TANAH",
      payload: !modalMapBidangTanah,
    });
  };

  const dataJsonIsWakil = [
    {
      value: false,
      label: "TIDAK",
    },
    {
      value: true,
      label: "YA",
    },
  ];

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyAMB0rItnkhOhf5Y_Wy374zqK7V8JsJRr4",
  });

  const saveSearchPlace = (item) => {
    if (getValues("IsAlamat") === "Alamat Bidang Tanah")
      placesService?.getDetails(
        {
          placeId: item.place_id,
        },
        (placeDetails) => {
          setValue("Latitude", placeDetails.geometry.location.lat(), {
            shouldValidate: true,
          });
          setValue("Longitude", placeDetails.geometry.location.lng(), {
            shouldValidate: true,
          });
        }
      );
    setValue("SearchAlamat", item.description);
  };

  const columnsFileLainnyaOld = [
    {
      title: "AKSI",
      field: "internal_action",
      render: (rowData) => (
        <>
          <div style={{}}>
            <CTooltip content="Hapus Pemanen" placement="right">
              <button
                type="button"
                className="btn btn-sm btn-danger ml-1"
                onClick={() =>
                  dispatch(
                    apiDeleteFilePermohonanDokumen(
                      rowData.IdFile,
                      `${rowData.JenisFile}`,
                      id
                    )
                  )
                }
              >
                <CIcon name="cil-trash" />
              </button>
            </CTooltip>
          </div>
        </>
      ),
    },
    {
      title: "DOKUMEN",
      field: "JenisFile",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "FILE",
      field: "NamaFile",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
      render: (rowData) => (
        <>
          <a
            href={`${rowData.PathUrl}/${rowData.NamaFile}`}
            target="_blank"
            className="text-info"
          >
            Lihat File <CIcon size={"xs"} name={"cilExternalLink"} />
          </a>
        </>
      ),
    },
  ];
  return (
    <>
      <form onSubmit={handleSubmit(save)}>
        <CCardBody>
          <div className="row">
            <div className="col-md-6">
              <input
                hidden
                defaultValue={IdPermohonan}
                name="IdPermohonan"
                className="form-control"
                {...register("IdPermohonan")}
              />
              <div className="alert alert-warning">
                <p>
                  <SymbolRequired />
                  Apakah permohonan ini diwakilkan ?
                </p>
                <div className="form-group">
                  <Controller
                    control={control}
                    defaultValue={IsDiwakilkan}
                    name="IsDiwakilkan"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonIsWakil}
                        value={getValues("IsDiwakilkan")}
                        isDisabled={id !== null}
                        onChange={(val) => {
                          field.onChange(val);
                          setValue("IsDiwakilkan", val, {
                            shouldValidate: true,
                          });
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.IsDiwakilkan && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  LAYANAN PENGUKURAN & PEMETAAN
                </label>
                <Controller
                  control={control}
                  defaultValue={IdJenisPermohonan}
                  name="IdJenisPermohonan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      options={dataComboJenisPermohonan}
                      value={getValues("IdJenisPermohonan")}
                      onChange={(val) => {
                        field.onChange(val);
                        onPermohonan(val, "JENIS_PERMOHONAN");
                      }}
                      isDisabled={id !== null}
                      name="IdJenisPermohonan"
                      isClearable={false}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.IdJenisPermohonan && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      PROVINSI (LOKASI BIDANG TANAH)
                    </label>
                    <Controller
                      control={control}
                      defaultValue={IdProv}
                      name="IdProv"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboProv}
                          value={getValues("IdProv")}
                          isDisabled={id !== null}
                          onChange={(val) => {
                            field.onChange(val);
                            onTimKjsb(val);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      KOTA/KABUPATEN
                    </label>
                    <Controller
                      control={control}
                      defaultValue={IdKabKot}
                      name="IdKabKot"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKab}
                          value={getValues("IdKabKot")}
                          isDisabled={id !== null}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(
                              setDataComboKec("DATA_COMBO_KEC", val.value)
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      KECAMATAN
                    </label>
                    <Controller
                      control={control}
                      defaultValue={IdKec}
                      name="IdKec"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboKec}
                          value={getValues("IdKec")}
                          isDisabled={id !== null}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(
                              setDataComboDesa("DATA_COMBO_DESA", val.value)
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      DESA
                    </label>
                    <Controller
                      control={control}
                      defaultValue={IdDesa}
                      name="IdDesa"
                      render={({ field, ref }) => (
                        <ReactSelect
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={dataComboDesa}
                          value={getValues("IdDesa")}
                          isDisabled={id !== null}
                          onChange={(val) => {
                            field.onChange(val);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  KANTOR JASA SURVEYOR BERLISENSI
                </label>
                <Controller
                  control={control}
                  defaultValue={IdKjsb}
                  name="IdKjsb"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboKjsb}
                      value={getValues("IdKjsb")}
                      onChange={(val) => {
                        field.onChange(val);
                        onTimSb(val);
                      }}
                      isDisabled={id !== null}
                      isClearable={false}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.IdKjsb && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  SURVEYOR BERLISENSI
                </label>
                <Controller
                  control={control}
                  defaultValue={IdSurveyor}
                  name="IdSurveyor"
                  render={({ field, ref }) => (
                    <ReactSelect
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={dataComboSurveyor}
                      value={getValues("IdSurveyor")}
                      onChange={(val) => {
                        dispatch(setDataCekMemberExpiredSurveyor(val, field));
                      }}
                      formatOptionLabel={(srv) => (
                        <div className="d-flex align-items-center">
                          <img
                            src={srv.image || "-"}
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = IMGNoPhoto;
                            }}
                            alt="foto-sb"
                          />
                          <span className="ml-3">{srv.label}</span>
                        </div>
                      )}
                      isClearable={false}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.IdSurveyor && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>

              <div
                className="form-group"
                style={{
                  display: !IsInputJumlahBidang ? "none" : "block",
                }}
              >
                <label>JUMLAH BIDANG SEBELUM</label>
                <input
                  type="number"
                  defaultValue={JumlahBidangSebelum}
                  name="JumlahBidangSebelum"
                  className="form-control"
                  placeholder="Ketikan Jumlah bidang sebelum..."
                  {...register("JumlahBidangSebelum", {
                    required: IsInputJumlahBidang,
                  })}
                />
                {errors.JumlahBidangSebelum && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
              <div
                className="form-group"
                style={{
                  display: !IsInputJumlahBidang ? "none" : "block",
                }}
              >
                <label>JUMLAH BIDANG SETELAH</label>
                <input
                  type="number"
                  defaultValue={JumlahBidangSetelah}
                  name="JumlahBidangSetelah"
                  className="form-control"
                  placeholder="Ketikan Jumlah bidang setelah..."
                  {...register("JumlahBidangSetelah", {
                    required: IsInputJumlahBidang,
                  })}
                />
                {errors.JumlahBidangSetelah && (
                  <span className="text-danger">Kolom wajib diisi</span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  NAMA (Pemohon)
                </label>
                <input
                  defaultValue={Nama}
                  name="Nama"
                  className="form-control"
                  placeholder="Ketikan Nama..."
                  {...register("Nama", { required: "Kolom wajib diisi" })}
                />
                {errors.Nama && (
                  <span className="text-danger">{errors.Nama?.message}</span>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      NO. KTP (Pemohon)
                    </label>
                    <input
                      type="number"
                      defaultValue={NoKtp}
                      name="NoKtp"
                      className="form-control"
                      placeholder="Ketikan Nomor KTP..."
                      {...register("NoKtp", {
                        required: "No KTP wajib diisi",
                        min: 1000000000000000,
                        max: 9999999999999999n,
                      })}
                    />
                    {errors.NoKtp && (
                      <span className="text-danger">
                        {errors.NoKtp?.message}
                      </span>
                    )}
                    {errors.NoKtp && errors.NoKtp.type === "min" && (
                      <span className="text-danger">No KTP belum 16 digit</span>
                    )}
                    {errors.NoKtp && errors.NoKtp.type === "max" && (
                      <span className="text-danger">
                        Harus 16 digit tidak lebih
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <SymbolRequired />
                      NO. HP (Pemohon)
                    </label>
                    <input
                      type="number"
                      defaultValue={NoHp}
                      name="NoHp"
                      className="form-control"
                      placeholder="Ketikan Nomor HP..."
                      {...register("NoHp", {
                        required: "No HP wajib diisi",
                        min: 1000000000,
                        max: 999999999999,
                      })}
                    />
                    {errors.NoHp?.message && (
                      <span className="text-danger">
                        {errors.NoHp?.message}
                      </span>
                    )}
                    {errors.NoHp && errors.NoHp.type === "min" && (
                      <span className="text-danger">
                        No HP minimal 10 digit
                      </span>
                    )}
                    {errors.NoHp && errors.NoHp.type === "max" && (
                      <span className="text-danger">Maksimal 13 digit</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>
                  <SymbolRequired />
                  EMAIL (Pemohon)
                </label>
                <input
                  defaultValue={Email}
                  name="Email"
                  className="form-control"
                  placeholder="Ketikan Email..."
                  {...register("Email", {
                    required: "Email wajib diisi",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Masukan email yang benar",
                    },
                  })}
                />
                {errors.Email && (
                  <span className="text-danger">{errors.Email?.message}</span>
                )}
              </div>
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  ALAMAT (Pemohon)
                </label>
                <textarea
                  defaultValue={Alamat}
                  name="Alamat"
                  className="form-control"
                  placeholder="Ketikan Alamat pemohon..."
                  {...register("Alamat", {
                    required: "Kolom wajib diisi",
                  })}
                  onClick={() => {
                    showModalPencarianAlamat();
                    setValue("IsAlamat", "Alamat (Pemohon)");
                    setValue("SearchAlamat", "");
                    getPlacePredictions({ input: "" });
                  }}
                />
                {errors.Alamat && (
                  <span className="text-danger">{errors.Alamat?.message}</span>
                )}
              </div>
              <div className="form-group">
                <label>
                  <SymbolRequired />
                  LUAS TANAH (M&sup2;)
                </label>
                <input
                  type="number"
                  defaultValue={LuasTanah}
                  name="LuasTanah"
                  className="form-control"
                  placeholder="Ketikan Luas tanah..."
                  {...register("LuasTanah", {
                    required: "Kolom wajib diisi",
                  })}
                />
                {errors.LuasTanah && (
                  <span className="text-danger">
                    {errors.LuasTanah?.message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>
                  <SymbolRequired />
                  ALAMAT BIDANG TANAH
                </label>
                <textarea
                  defaultValue={AlamatBidangTanah}
                  name="AlamatBidangTanah"
                  className="form-control"
                  placeholder="Ketikan Alamat bidang tanah..."
                  {...register("AlamatBidangTanah", {
                    required: "Kolom wajib diisi",
                  })}
                  onClick={() => {
                    showModalPencarianAlamat();
                    setValue("IsAlamat", "Alamat Bidang Tanah");
                    setValue("SearchAlamat", "");
                    getPlacePredictions({ input: "" });
                  }}
                />
                {errors.AlamatBidangTanah && (
                  <span className="text-danger">
                    {errors.AlamatBidangTanah?.message}
                  </span>
                )}
              </div>
              {getValues("Latitude") && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>LATITUDE</label>
                      <input
                        type="text"
                        defaultValue={Latitude}
                        name="Latitude"
                        className="form-control"
                        placeholder="Ketikan Latitude..."
                        {...register("Latitude", {
                          required: "Latitude wajib diisi",
                        })}
                      />
                      {errors.Latitude?.message && (
                        <span className="text-danger">
                          {errors.Latitude?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>LONGITUDE</label>
                      <input
                        type="text"
                        defaultValue={Longitude}
                        name="Longitude"
                        className="form-control"
                        placeholder="Ketikan Longitude..."
                        {...register("Longitude", {
                          required: "Longitude wajib diisi",
                        })}
                      />
                      {errors.Longitude?.message && (
                        <span className="text-danger">
                          {errors.Longitude?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <a
                      href="javascript:void(0)"
                      onClick={showModalMapBidangTanah}
                      className="text-warning"
                    >
                      <CIcon name="cil-map" /> Lihat Lokasi Bidang Tanah
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12">
              {fileSyaratPermohonan.length !== 0 && (
                <>
                  <hr />
                  <div className="alert alert-primary">
                    <b>Info Upload File</b> <br />
                    1. Ukuran file maksimal 2MB <br />
                    2. Format file yang digunakan wajib .pdf
                    <br />
                    3. Simbol tanda file wajib diupload <i>(*)</i>
                  </div>

                  <CCard>
                    <CCardHeader>
                      <CButton onClick={toggleDokSyarat} className={"mb-1"}>
                        <CIcon name="cil-file" className="mr-3" />
                        Upload Berkas Persyaratan
                      </CButton>
                    </CCardHeader>
                    <CCollapse show={collapseDokSyarat}>
                      <CCardBody>
                        <div className="row">
                          {fileSyaratPermohonan.map((v, i) => {
                            var strRequired = v.IsRequired;
                            if (v.Nama === "Surat Kuasa") {
                              if (getValues("IsDiwakilkan")?.value)
                                strRequired = true;
                              if (!getValues("IsDiwakilkan")?.value)
                                strRequired = false;
                            }
                            if (v.Nama === "Kartu Identitas Penerima Kuasa") {
                              if (getValues("IsDiwakilkan")?.value)
                                strRequired = true;
                              if (!getValues("IsDiwakilkan")?.value)
                                strRequired = false;
                            }
                            var fileOld = berkasPermohonanSyarats?.filter(
                              (x) => x.IdFile === v.Id
                            )[0];
                            return (
                              <div key={i} className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    {strRequired && <SymbolRequired />}
                                    {v.Nama.toUpperCase()}
                                  </label>
                                  <Controller
                                    control={control}
                                    name={`FileSyarats[${i}]`}
                                    render={({ ref }) => (
                                      <input
                                        ref={ref}
                                        onChange={(val) => {
                                          onCheckFileSyarat(val, i, v.Id);
                                        }}
                                        name={`FileSyarats[${i}]`}
                                        type={"file"}
                                        className="form-control"
                                      />
                                    )}
                                    rules={{
                                      required: !fileOld ? strRequired : false,
                                    }}
                                  />
                                  {fileOld && (
                                    <small>
                                      <a
                                        href={`${fileOld?.PathUrl}/${fileOld?.NamaFile}`}
                                        target="_blank"
                                        className="text-info"
                                      >
                                        Lihat File{" "}
                                        <CIcon
                                          size={"xs"}
                                          name={"cilExternalLink"}
                                        />
                                      </a>
                                    </small>
                                  )}
                                  {errors.FileSyarats?.[i] && (
                                    <span className="text-danger">
                                      File wajib diupload
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </CCardBody>
                    </CCollapse>
                  </CCard>

                  <CRow>
                    <CCol md={id === null ? "12" : "6"}>
                      <CCard>
                        <CCardHeader>
                          <CButton
                            onClick={toggleDokLainnya}
                            className={"mb-1"}
                          >
                            <CIcon name="cil-file" className="mr-3" />
                            Upload Dokumen Lainnya
                          </CButton>
                        </CCardHeader>
                        <CCollapse show={collapseDokLainnya}>
                          <CCardBody>
                            {fields.map((v, i) => {
                              return (
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <label>
                                        <SymbolRequired />
                                        Nama Dokumen {i + 1}
                                      </label>
                                      <input
                                        type="text"
                                        name="NamaDokumen"
                                        className="form-control"
                                        placeholder="Ketikan Nama Dokumen..."
                                        {...register(
                                          `FileLainnyas[${i}].Nama`,
                                          {
                                            required: true,
                                          }
                                        )}
                                      />
                                      {errors.FileLainnyas?.[i]?.Nama && (
                                        <span className="text-danger">
                                          Kolom wajib diisi
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <label>
                                        <SymbolRequired />
                                        File Dokumen {i + 1}
                                      </label>
                                      <Controller
                                        control={control}
                                        name={`FileLainnyas[${i}].File`}
                                        render={({ ref }) => (
                                          <input
                                            ref={ref}
                                            onChange={(val) => {
                                              onCheckFileLainnya(val, i);
                                            }}
                                            name={`FileLainnyas[${i}].File`}
                                            type={"file"}
                                            className="form-control"
                                          />
                                        )}
                                        rules={{
                                          required: true,
                                        }}
                                      />
                                      {errors.FileLainnyas?.[i]?.File && (
                                        <span className="text-danger">
                                          File wajib diupload
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-1 d-flex align-items-center">
                                    <CButton
                                      color="danger"
                                      onClick={() => remove(i)}
                                    >
                                      <CIcon
                                        name="cil-trash"
                                        color="danger"
                                      ></CIcon>
                                    </CButton>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="form-group">
                              <CButton
                                color="success"
                                onClick={() =>
                                  append({ NamaFile: "", FileUpload: "" })
                                }
                              >
                                <CIcon name="cil-plus" color="white"></CIcon>{" "}
                                Tambah Dokumen
                              </CButton>
                            </div>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </CCol>
                    {id !== null && (
                      <CCol md="6">
                        <CCard>
                          <CCardHeader>
                            <CButton className={"mb-1"}>
                              <CIcon name="cil-file" className="mr-3" />
                              File Dokumen Lainnya
                            </CButton>
                          </CCardHeader>
                          <CCardBody>
                            <CAlert color="info">
                              <b>Info</b>
                              <p>
                                File dokumen pada tabel dapat dihapus secara
                                permanen dengan klik tombol hapus (tidak
                                berpengaruh dengan tombol simpan data)
                                <br />
                                **berhati-hatilah dalam hapus file dokumen
                              </p>
                            </CAlert>
                            <DataTable
                              columns={columnsFileLainnyaOld}
                              data={berkasPermohonanLainnyas}
                              title={
                                <span style={{ color: "#3C4B64" }}>
                                  DAFTAR DOKUMEN
                                </span>
                              }
                            />
                          </CCardBody>
                        </CCard>
                      </CCol>
                    )}
                  </CRow>
                </>
              )}
            </div>
          </div>
        </CCardBody>
        <CCardFooter align="right">
          <CButton color="secondary" onClick={() => history.goBack()}>
            BATAL
          </CButton>
          <CButton color="primary" type="submit" className="ml-2">
            SIMPAN DATA
          </CButton>
        </CCardFooter>
      </form>

      <CModal show={modalPencarianAlamat} onClose={showModalPencarianAlamat}>
        <CModalHeader closeButton>
          Pencarian {getValues("IsAlamat")}
        </CModalHeader>
        <CModalBody>
          <div className="row">
            <div className="col-md-12">
              <form id="form">
                <div className="form-group">
                  <label>PENCARIAN ALAMAT</label>
                  <Controller
                    control={control}
                    name="SearchAlamat"
                    render={({ field, ref }) => (
                      <textarea
                        ref={ref}
                        name="SearchAlamat"
                        className="form-control"
                        placeholder={`Ketikan ${getValues("IsAlamat")}...`}
                        value={getValues("SearchAlamat")}
                        onChange={(evt) => {
                          field.onChange(evt.target.value);
                          getPlacePredictions({ input: evt.target.value });
                        }}
                        autoFocus
                      />
                    )}
                  />
                </div>
                <CButton
                  color="primary"
                  type="button"
                  block
                  onClick={() => {
                    showModalPencarianAlamat();
                    if (getValues("IsAlamat") === "Alamat Bidang Tanah") {
                      setValue("AlamatBidangTanah", getValues("SearchAlamat"));
                      setValue("Latitude", "-1.4509444");
                      setValue("Longitude", "117.62527");
                    } else {
                      setValue("Alamat", getValues("SearchAlamat"));
                    }
                  }}
                >
                  SIMPAN ALAMAT
                </CButton>
              </form>
              <hr />
              {isPlacePredictionsLoading ? (
                <CSpinner color="primary" size="sm" />
              ) : placePredictions.length !== 0 ? (
                <label>HASIL PENCARIAN :</label>
              ) : (
                <div className="alert alert-warning">
                  Tidak ketemu? silahkan isi manual disini!
                </div>
              )}
              {placePredictions.map((item, index) => {
                return (
                  <div key={index}>
                    <CCallout
                      color="primary"
                      className={"bg-white"}
                      style={{ cursor: "pointer" }}
                      onClick={() => saveSearchPlace(item)}
                    >
                      <div className="p-1">
                        <p>{item.description}</p>
                      </div>
                    </CCallout>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </CModalBody>
      </CModal>

      <CModal
        size="lg"
        show={modalMapBidangTanah}
        onClose={showModalMapBidangTanah}
      >
        <CModalHeader closeButton>Map Lokasi Bidang Tanah</CModalHeader>
        <CModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>LATITUDE</label>
                <input
                  type="text"
                  defaultValue={Latitude}
                  name="Latitude"
                  className="form-control"
                  placeholder="Ketikan Latitude..."
                  {...register("Latitude", {
                    required: "Latitude wajib diisi",
                  })}
                />
                {errors.Latitude?.message && (
                  <span className="text-danger">
                    {errors.Latitude?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>LONGITUDE</label>
                <input
                  type="text"
                  defaultValue={Longitude}
                  name="Longitude"
                  className="form-control"
                  placeholder="Ketikan Longitude..."
                  {...register("Longitude", {
                    required: "Longitude wajib diisi",
                  })}
                />
                {errors.Longitude?.message && (
                  <span className="text-danger">
                    {errors.Longitude?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <LeafletPopup
                markerDraggable={
                  <Marker
                    draggable={true}
                    eventHandlers={eventDragMap}
                    position={{
                      lat: getValues("Latitude"),
                      lng: getValues("Longitude"),
                    }}
                    ref={markerRef}
                  >
                    <Popup minWidth={90}>
                      {getValues("AlamatBidangTanah")}
                    </Popup>
                  </Marker>
                }
              />
            </div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

const SymbolRequired = () => (
  <span className="text-danger mr-1 font-weight-bold ">
    <i>*</i>
  </span>
);

export default FormAddEdit;
