import React, { useEffect, useState } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import {
  DataTable,
  ReactDatePicker,
  ReactSelect,
  Upload,
} from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setImgPreview,
  setDataIklans,
  setFormIklan,
  setDataIklan,
  apiAddIklan,
  apiUpdateIklan,
  apiDeleteIklan,
  apiApproveIklan,
  apiRejectIklan,
} from "src/config/redux/action";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { useForm, Controller } from "react-hook-form";
import { IMGNoPhoto } from "src/assets";

function DataIklanList() {
  const { dataIklans, formIklan, fileImageIklanPreview, modalIklan } =
    useSelector((state) => state.iklanReducer);
  const {
    IdIklan,
    Judul,
    TipeKontak,
    Kontak,
    MinTanggalTayang,
    MaxTanggalTayang,
    FileUpload,
    Status,
    IsTipeKontak,
  } = formIklan;
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("IdIklan", IdIklan);
    setValue("Judul", Judul);
    setValue("TipeKontak", TipeKontak);
    setValue("Kontak", Kontak);
    setValue("MinTanggalTayang", MinTanggalTayang);
    setValue("MaxTanggalTayang", MaxTanggalTayang);
    setValue("FileUpload", FileUpload);
    setValue("Status", Status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IdIklan,
    Judul,
    TipeKontak,
    Kontak,
    MinTanggalTayang,
    MaxTanggalTayang,
    FileUpload,
    Status,
  ]);

  useEffect(() => {
    dispatch(setDataIklans());
  }, [dispatch]);

  const handleEdit = (isAction, idIklan) => {
    reset();

    var idFile = document.getElementById("fileImage");
    idFile.value = idFile.defaultValue;

    if (isAction === "add") {
      setIsEdit(false);
      showModal();
      dispatch({ type: "RESET_FORM_IKLAN" });
    }
    if (isAction === "edit") {
      setIsEdit(true);
      dispatch(setDataIklan(idIklan));
    }
  };

  const btnAddIklan = (data) => dispatch(apiAddIklan(data));

  const btnEditIklan = (data) => dispatch(apiUpdateIklan(data));

  const showModal = () =>
    dispatch({ type: "MODAL_IKLAN", payload: !modalIklan });

  const onImgIklan = (e) => {
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target);
    const file = e.target.files[0];

    if (file !== undefined) {
      setValue("FileUpload", file);
      dispatch(
        setImgPreview("FILE_IMAGE_IKLAN_PREVIEW", URL.createObjectURL(file))
      );
    } else {
      setValue("FileUpload", "");
      dispatch(
        setImgPreview("FILE_IMAGE_IKLAN_PREVIEW", fileImageIklanPreview)
      );
    }
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 400,
        minWidth: 400,
      },
      headerStyle: {
        width: 400,
        minWidth: 400,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary mr-1"
              onClick={() => handleEdit("edit", rowData.IdIklan)}
            >
              <CIcon name="cil-pencil" /> PERBARUI
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger mr-1"
              onClick={() =>
                dispatch(apiDeleteIklan(rowData.IdIklan, `${rowData.Judul}`))
              }
            >
              <CIcon name="cil-trash" /> HAPUS
            </button>
            <button
              type="button"
              className="btn btn-sm btn-success mr-1"
              onClick={() => dispatch(apiApproveIklan(rowData.IdIklan))}
            >
              <CIcon name="cil-check" /> APPROVE
            </button>
            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => dispatch(apiRejectIklan(rowData.IdIklan))}
            >
              <CIcon name="cil-x" /> REJECT
            </button>
          </div>
        </>
      ),
    },
    {
      title: "IKLAN",
      field: "NamaFile",
      render: (rowData) => (
        <img
          src={rowData.NamaFile}
          style={{
            width: 50,
            height: 50,
            objectFit: "contain",
            borderRadius: 5,
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = IMGNoPhoto;
          }}
          alt="iklan-img"
        />
      ),
    },
    {
      title: "JUDUL",
      field: "Judul",
    },
    {
      title: "KONTAK",
      field: "Kontak",
    },
    {
      title: "TGL. TAYANG MULAI/AKHIR",
      field: "MinTanggalTayang",
      render: (rowData) => (
        <>
          <span className="badge badge-success">
            {rowData.MinTanggalTayang}
          </span>{" "}
          /{" "}
          <span className="badge badge-danger">{rowData.MaxTanggalTayang}</span>
        </>
      ),
    },
    {
      title: "STATUS",
      field: "StrStatus",
      render: (rowData) =>
        rowData.Status.Id === 1 ? (
          <span className="badge badge-info">AKTIF</span>
        ) : (
          <span className="badge badge-danger">TIDAK AKTIF</span>
        ),
    },
  ];

  const dataJsonTipeKontak = [
    {
      value: "1",
      label: "Telepon",
    },
    {
      value: "2",
      label: "Email",
    },
  ];

  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-success mb-4"
        onClick={() => handleEdit("add")}
      >
        <CIcon name="cil-user" /> TAMBAH IKLAN BARU
      </button>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataIklans}
            title={<span style={{ color: "#3C4B64" }}>DATA IKLAN</span>}
            options={{
              paging: true,
              headerStyle: { position: "sticky", top: 0 },
              overflowX: "auto",
            }}
          />
        </CCard>
      </div>

      <CModal show={modalIklan} onClose={showModal}>
        <CModalHeader closeButton>
          {isEdit ? `PERBARUI DATA` : `TAMBAH BARU`}
        </CModalHeader>
        <form onSubmit={handleSubmit(isEdit ? btnEditIklan : btnAddIklan)}>
          <CModalBody>
            <CRow>
              <CCol md="12">
                <input
                  hidden
                  defaultValue={IdIklan}
                  name="IdIklan"
                  className="form-control"
                  {...register("IdIklan")}
                />
                <CFormGroup>
                  <CLabel>Judul</CLabel>
                  <input
                    defaultValue={Judul}
                    name="Judul"
                    className="form-control"
                    placeholder="Ketikan Judul..."
                    {...register("Judul", { required: "Kolom wajib diisi" })}
                  />
                  {errors.Judul && (
                    <span className="text-danger">{errors.Judul?.message}</span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <label>Tipe Kontak </label>
                  <Controller
                    control={control}
                    defaultValue={TipeKontak}
                    name="TipeKontak"
                    render={({ field, value, name, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonTipeKontak}
                        value={getValues("TipeKontak")}
                        isSearchable={false}
                        onChange={(val) => {
                          field.onChange(val);
                          dispatch(setFormIklan("IsTipeKontak", val.value));
                          dispatch(setFormIklan("Kontak", ""));
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.TipeKontak && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <CFormGroup
                  style={{ display: IsTipeKontak === 1 ? "block" : "none" }}
                >
                  <label>Kontak </label>
                  <input
                    type="number"
                    defaultValue={Kontak}
                    name="KontakTelepon"
                    className="form-control"
                    placeholder="Ketikan No. Handphone..."
                    {...register("KontakTelepon", {
                      required:
                        IsTipeKontak === 1 ? "Kolom wajib diisi" : false,
                      min: 1000000000,
                      max: 999999999999,
                    })}
                  />
                  {errors.KontakTelepon?.message && (
                    <span className="text-danger">Kontak wajib diisi</span>
                  )}
                  {errors.KontakTelepon &&
                    errors.KontakTelepon.type === "min" && (
                      <span className="text-danger">
                        No HP minimal 10 digit
                      </span>
                    )}
                  {errors.KontakTelepon &&
                    errors.KontakTelepon.type === "max" && (
                      <span className="text-danger">Maksimal 13 digit</span>
                    )}
                </CFormGroup>
                <CFormGroup
                  style={{ display: IsTipeKontak !== 1 ? "block" : "none" }}
                >
                  <CLabel>Email</CLabel>
                  <input
                    defaultValue={Kontak}
                    name="KontakEmail"
                    className="form-control"
                    placeholder="Ketikan Email..."
                    {...register("KontakEmail", {
                      required:
                        IsTipeKontak !== 1 ? "Kolom wajib diisi" : false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Masukan email yang benar",
                      },
                    })}
                  />
                  {errors.KontakEmail && (
                    <span className="text-danger">
                      {errors.KontakEmail?.message}
                    </span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Tgl. Mulai Tayang </CLabel>
                  <Controller
                    control={control}
                    defaultValue={MinTanggalTayang}
                    name="MinTanggalTayang"
                    render={({ field, onChange, value }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Mulai Tayang..."
                        selected={field.value}
                        value={getValues("MinTanggalTayang")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.MinTanggalTayang && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Tgl. Akhir Tayang</CLabel>
                  <Controller
                    control={control}
                    defaultValue={MaxTanggalTayang}
                    name="MaxTanggalTayang"
                    render={({ field, onChange, value }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Akhir Tayang..."
                        selected={field.value}
                        value={getValues("MaxTanggalTayang")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.MaxTanggalTayang && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <div className="alert alert-primary">
                  <b>Info Upload File</b> <br />
                  1. Ukuran file maksimal 2MB <br />
                  2. Format file yang digunakan wajib .pdf .png .jpg
                </div>
                <CFormGroup>
                  <label>Iklan </label>
                  <Upload
                    id="fileImage"
                    onChange={(e) => onImgIklan(e)}
                    img={fileImageIklanPreview}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              {isEdit ? "PERBARUI DATA" : "SIMPAN DATA"}
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </div>
  );
}

export default DataIklanList;
