import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CCallout,
  CCard,
  CCardBody,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components/atoms";
import { FUNCIndoDate } from "src/config";

const PopupPelayanan = () => {
  const { modalPelayananPreviewBerkas, dataPelayananPreviewBerkas } =
    useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch({
      type: "MODAL_PELAYANAN_PREVIEW_BERKAS",
      payload: false,
    });
  };
  const columnsFiles = [
    {
      title: "DOKUMEN",
      field: "JenisFile",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "FILE",
      field: "NamaFile",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
      render: (rowData) => (
        <>
          <a
            href={`${rowData.PathUrl}/${rowData.NamaFile}`}
            target="_blank"
            className="text-info"
            rel="noreferrer"
          >
            Lihat File <CIcon size={"xs"} name={"cilExternalLink"} />
          </a>
        </>
      ),
    },
  ];
  return (
    <CModal size="xl" show={modalPelayananPreviewBerkas} onClose={closeModal}>
      <CModalHeader closeButton>Lihat Berkas</CModalHeader>
      <CModalBody>
        <CTabs activeTab="detail">
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink data-tab="detail">
                <CIcon name="cil-address-book" className="mr-2" />
                Informasi Detail
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="files">
                <CIcon name="cil-file" className="mr-2" />
                Daftar Berkas
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane data-tab="detail">
              {dataPelayananPreviewBerkas && (
                <CRow>
                  {dataPelayananPreviewBerkas.IsDiwakilkan && (
                    <CCol md="12">
                      <CAlert color="info">
                        <b>Pemohon pelayanan ini diwakilkan orang lain!</b>
                      </CAlert>
                    </CCol>
                  )}
                  <CCol md="6">
                    <div className="table table-responsive">
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>No. Permohonan &nbsp;</td>
                          <td>
                            :{" "}
                            <code>
                              #{dataPelayananPreviewBerkas.NoPermohonan}
                            </code>
                          </td>
                        </tr>
                        <tr>
                          <td>Layanan</td>
                          <td>
                            :{" "}
                            <b>
                              {dataPelayananPreviewBerkas.JenisPermohonan.Nama}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Wilayah Kerja</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Wilker.Nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Kabupaten/Kota</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Kabupaten.Nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Kecamatan</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Kecamatan.Nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Desa</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Desa.Nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Latitude, Longitude</td>
                          <td>
                            :
                            <b>
                              {" "}
                              {dataPelayananPreviewBerkas.Latitude},{" "}
                              {dataPelayananPreviewBerkas.Longitude}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>KJSB</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Kjsb.Nama}</b>
                          </td>
                        </tr>
                        {dataPelayananPreviewBerkas.Surveyor && (
                          <tr>
                            <td>Surveyor</td>
                            <td>
                              :{" "}
                              <b>
                                {dataPelayananPreviewBerkas?.Surveyor?.Nama}
                              </b>
                            </td>
                          </tr>
                        )}
                        {dataPelayananPreviewBerkas.NoSuratTugas && (
                          <tr>
                            <td>No. Surat Tugas</td>
                            <td>
                              :{" "}
                              <b>{dataPelayananPreviewBerkas?.NoSuratTugas}</b>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </CCol>
                  <CCol md="6">
                    <div className="table table-responsive">
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>Nama Pemohon</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Nama}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>No. KTP Pemohon</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.NoKtp}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Alamat Pemohon</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Alamat}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>No. HP Pemohon</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.NoHp}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Email Pemohon</td>
                          <td>
                            : <b>{dataPelayananPreviewBerkas.Email}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Luas Tanah</td>
                          <td>
                            :{" "}
                            <b>
                              {dataPelayananPreviewBerkas.LuasTanah.toLocaleString(
                                "id-ID"
                              )}{" "}
                              m&sup2;
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Alamat Bidang Tanah</td>
                          <td>
                            :{" "}
                            <b>
                              {dataPelayananPreviewBerkas.AlamatBidangTanah}
                            </b>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </CCol>
                  <CCol md="8">
                    <hr />
                    <div className="mx-3">
                      <p>Lokasi Bidang Tanah</p>
                      {dataPelayananPreviewBerkas.Latitude !== null && (
                        <center>
                          <iframe
                            src={`https://maps.google.com/maps?q=${dataPelayananPreviewBerkas.Latitude}, ${dataPelayananPreviewBerkas.Longitude}&z=15&output=embed`}
                            height="600"
                            frameborder="0"
                            title="Lokasi"
                            style={{ border: 0, width: "100%" }}
                          ></iframe>
                        </center>
                      )}
                    </div>
                  </CCol>
                  <CCol md="4">
                    <hr />
                    <p>Status Perjalanan Berkas</p>
                    {dataPelayananPreviewBerkas.Statuss.map((item, index) => {
                      return (
                        <CCallout key={index} color="primary">
                          <small>
                            {FUNCIndoDate(item.Tanggal)}, {item.Jam}
                          </small>{" "}
                          <br />
                          <b>{item.Status}</b> <br />
                          Catatan : {item.Catatan ?? "tidak ada"}
                        </CCallout>
                      );
                    })}
                  </CCol>
                </CRow>
              )}
            </CTabPane>
            <CTabPane data-tab="files">
              <div className="mt-3">
                {/* FILE SYARAT */}
                <CRow>
                  <CCol md="6">
                    <CCard>
                      <CCardBody>
                        <DataTable
                          columns={columnsFiles}
                          data={dataPelayananPreviewBerkas?.FileSyarats}
                          title={
                            <span style={{ color: "#3C4B64" }}>
                              DAFTAR DOKUMEN SYARAT
                            </span>
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol md="6">
                    <CCard>
                      <CCardBody>
                        <DataTable
                          columns={columnsFiles}
                          data={dataPelayananPreviewBerkas?.FilePengukurans}
                          title={
                            <span style={{ color: "#3C4B64" }}>
                              DAFTAR DOKUMEN PENGUKURAN
                            </span>
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol md="6">
                    <CCard>
                      <CCardBody>
                        <DataTable
                          columns={columnsFiles}
                          data={
                            dataPelayananPreviewBerkas?.FileHasilPengolahans
                          }
                          title={
                            <span style={{ color: "#3C4B64" }}>
                              DAFTAR DOKUMEN PENGOLAHAN
                            </span>
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol md="6">
                    <CCard>
                      <CCardBody>
                        <DataTable
                          columns={columnsFiles}
                          data={dataPelayananPreviewBerkas?.FileHasilAkhirs}
                          title={
                            <span style={{ color: "#3C4B64" }}>
                              DAFTAR DOKUMEN HASIL AKHIR
                            </span>
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol md="12">
                    <CCard>
                      <CCardBody>
                        <DataTable
                          columns={columnsFiles}
                          data={dataPelayananPreviewBerkas?.FileLainnyas}
                          title={
                            <span style={{ color: "#3C4B64" }}>
                              DAFTAR DOKUMEN LAINNYA
                            </span>
                          }
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
                {/* FILE SYARAT */}
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CModalBody>
    </CModal>
  );
};

export default PopupPelayanan;
