import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import { http, URLSVC } from "src/config";

const TheSidebar = () => {
  const { sidebarShow } = useSelector((state) => state.sidebarReducer);
  const dispatch = useDispatch();

  const [menu, setMenu] = useState([]);
  React.useEffect(() => {
    http.get(`${URLSVC}/User/GetMenus`).then((res) => {
      let data = res.data;
      let arrMenu = [
        {
          _tag: "CSidebarNavItem",
          name: "Halaman Utama",
          to: "/home",
          icon: "cil-home",
        },
        ...data.Data,
      ];
      setMenu(arrMenu);
    });
  }, []);

  return (
    <CSidebar
      show={sidebarShow}
      onShowChange={(val) => dispatch({ type: "SET", payload: val })}
      className="bg-primary"
    >
      <CSidebarBrand className="d-lg-down-none bg-white" to="/">
        <div className="d-flex align-items-center justify-content-center w-100 font-weight-bold">
          <img
            src={"assets/images/logo-surlis-2.png"}
            className="c-sidebar-brand-full p-2"
            name="logo-negative"
            width="auto"
            height="53"
            alt="logo-surlis"
          />
        </div>
        <span className="c-sidebar-brand-minimized text-dark">
          <b>SURLIS</b>
        </span>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={menu}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
