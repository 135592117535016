import React, { useEffect, useState } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable, ReactSelect, Upload } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataInputHasilCekPlots,
  setDataInputHasilCekPlot,
  apiInputHasilCheckPlot,
  setDataPermohonans,
} from "src/config/redux/action";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { Controller, useForm } from "react-hook-form";

const CekPlotInputHasil = () => {
  const {
    dataInputHasilCekPlots,
    modalInputHasilCekPlot,
    formInputHasilCekPlot,
  } = useSelector((state) => state.cekPlotReducer);
  const dispatch = useDispatch();
  const [isCatatan, setIsCatatan] = useState(false);
  const { NoPermohonan, Nama, Catatan, StatusProses } = formInputHasilCekPlot;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("NoPermohonan", NoPermohonan);
    setValue("Nama", Nama);
    setValue("Catatan", Catatan);
    setValue("StatusProses", StatusProses);
  }, [NoPermohonan, Nama, Catatan, StatusProses]);

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_INPUT_HASIL_CEK_PLOTS"));
  }, [dispatch]);

  const handleModal = (idPermohonan) => {
    var idFile = document.getElementById(`fileHasilCekPlot`);
    idFile.value = idFile.defaultValue;

    reset();
    setValue("IdPermohonan", idPermohonan);
    dispatch(setDataInputHasilCekPlot(idPermohonan));
    showModal();
  };

  const showModal = () =>
    dispatch({
      type: "MODAL_INPUT_HASIL_CEK_PLOT",
      payload: !modalInputHasilCekPlot,
    });

  const btnUploadHasil = (data) => {
    dispatch(apiInputHasilCheckPlot(data));
  };

  const onFile = (e) => {
    FUNCValidateUploadFileSize(e.target, 10048, "10MB");
    FUNCValidateUploadFileExtension(e.target, [
      ".jpg",
      ".jpeg",
      ".pdf",
      ".png",
      ".zip",
    ]);
    const file = e.target.files[0];

    if (file !== undefined) {
      setValue("HasilCekPlot", file);
    } else {
      setValue("HasilCekPlot", null);
    }
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleModal(rowData.IdPermohonan)}
            >
              <CIcon name="cil-cloud-upload" /> UPLOAD HASIL
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          {rowData.StatusProses === "" && (
            <span className="badge badge-warning">Dalam Proses</span>
          )}
          {rowData.StatusProses === "Berkas Valid/Lengkap" && (
            <span className="badge badge-success">{rowData.StatusProses}</span>
          )}
          {rowData.StatusProses === "Berkas Tidak Valid/Lengkap" && (
            <span className="badge badge-danger">{rowData.StatusProses}</span>
          )}
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  const dataJsonStatusProses = [
    {
      value: "-1",
      label: "Bermasalah",
    },
    {
      value: "1",
      label: "Tidak Bermasalah",
    },
  ];

  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataInputHasilCekPlots}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>

      <CModal show={modalInputHasilCekPlot} onClose={showModal}>
        <CModalHeader closeButton>DETAIL PERMOHONAN</CModalHeader>
        <form id="form" onSubmit={handleSubmit(btnUploadHasil)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>
                <div className="form-group">
                  <label>STATUS CEK PLOT</label>
                  <Controller
                    control={control}
                    defaultValue={StatusProses}
                    name="StatusProses"
                    render={({ field, value, name, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonStatusProses}
                        value={getValues("StatusProses")}
                        onChange={(val) => {
                          field.onChange(val);
                          setIsCatatan(val.value === "-1");
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.StatusProses && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
                <div className="form-group">
                  <label>CATATAN</label>
                  <textarea
                    name="Catatan"
                    rows={5}
                    className="form-control"
                    placeholder="Ketikan Catatan..."
                    {...register("Catatan", {
                      required: isCatatan === true,
                    })}
                  />
                  {errors.Catatan && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>

                <div className="alert alert-primary">
                  <b>Info Upload File</b> <br />
                  1. Ukuran file maksimal 10MB <br />
                  2. Format file yang digunakan wajib .pdf .png .jpg .zip
                </div>
                <div className="form-group">
                  <label>HASIL CEK PLOT</label>
                  <Upload id="fileHasilCekPlot" onChange={(e) => onFile(e)} />
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              UPLOAD HASIL CEK PLOT
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </>
  );
};

export default CekPlotInputHasil;
