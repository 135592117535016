import React, { useEffect } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setDataUserInfo } from "src/config/redux/action";
import { IMGNoPhoto } from "../assets";

const TheHeaderDropdown = () => {
  const { fileImageProfilePreview, formProfile } = useSelector(
    (state) => state.ubahProfileReducer
  );
  const logoutClick = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("DATA_USER");
    Swal.fire(
      "Berhasil",
      `Anda telah keluar dari halaman utama !!!`,
      "success"
    ).then((e) => (window.location = "/"));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDataUserInfo());
  }, [dispatch]);

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={fileImageProfilePreview}
            className="c-avatar-img"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMGNoPhoto;
            }}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Pengaturan</strong>
        </CDropdownItem>
        <CDropdownItem disabled>
          <CIcon name="cil-settings" className="mfe-2" />
          Ubah Password
        </CDropdownItem>
        <small className="mx-2 badge badge-warning">
          Lakukan di aplikasi{" "}
          <a href="https://member.maski.or.id" target="_blank" rel="noreferrer">
            member
          </a>
        </small>

        <CDropdownItem divider />
        <CDropdownItem onClick={logoutClick}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Keluar
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
