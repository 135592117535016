import React, { useEffect } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable, Upload } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataPengolahan,
  apiProsesPengolahan,
  setDataPermohonans,
} from "src/config/redux/action";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";

const Pengolahan = () => {
  const history = useHistory();
  const { dataPengolahans, modalPengolahan, formPengolahan } = useSelector(
    (state) => state.pengolahanReducer
  );
  const dispatch = useDispatch();

  const { NoPermohonan, Nama } = formPengolahan;

  const { handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    setValue("NoPermohonan", NoPermohonan);
    setValue("Nama", Nama);
  }, [NoPermohonan, Nama]);

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_PENGOLAHANS", "pengolahan"));
  }, [dispatch]);

  const btnPengolahan = (data) => dispatch(apiProsesPengolahan(data));

  // const onFile = (e) => {
  //   FUNCValidateUploadFileSize(e.target, 10048, "10MB");
  //   FUNCValidateUploadFileExtension(e.target, [
  //     ".jpg",
  //     ".jpeg",
  //     ".pdf",
  //     ".png",
  //     ".zip",
  //   ]);
  //   const file = e.target.files[0];

  //   if (file !== undefined) {
  //     setValue("HasilPengolahan", file);
  //   } else {
  //     setValue("HasilPengolahan", null);
  //   }
  // };

  // const handleModal = (idPermohonan) => {
  //   var idFile = document.getElementById(`fileHasilPengolahan`);
  //   idFile.value = idFile.defaultValue;

  //   reset();
  //   setValue("IdPermohonan", idPermohonan);
  //   dispatch(setDataPengolahan(idPermohonan));
  // };

  // const showModal = () =>
  //   dispatch({
  //     type: "MODAL_PENGOLAHAN",
  //     payload: !modalPengolahan,
  //   });

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => 
                history.push(`/pengolahan/upload/${rowData.IdPermohonan}`)}
            >
              <CIcon name="cil-cloud-upload" /> UPLOAD
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          <span className="badge badge-warning">
            {rowData.StatusProses || "-"}
          </span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataPengolahans}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>
      {/* <CModal show={modalPengolahan} onClose={showModal}>
        <CModalHeader closeButton>DETAIL PERMOHONAN</CModalHeader>
        <form id="form" onSubmit={handleSubmit(btnPengolahan)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>
                <div className="alert alert-primary">
                  <b>Info Upload File</b> <br />
                  1. Ukuran file maksimal 10MB <br />
                  2. Format file yang digunakan wajib .pdf .png .jpg .zip
                </div>
                <div className="form-group">
                  <label>HASIL Pengolahan</label>
                  <Upload
                    id="fileHasilPengolahan"
                    onChange={(e) => onFile(e)}
                  />
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              SIMPAN
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal> */}
    </>
  );
};

export default Pengolahan;
