import { Login, DaftarPemohon, SimulasiBiayaLayanan } from "src/views/guest";

const guest = [
  {
    path: "/login-petugas",
    exact: true,
    name: "LOGIN PETUGAS",
    Component: Login,
  },
  {
    path: "/daftar-pemohon",
    exact: true,
    name: "FORM DAFTAR PEMOHON",
    Component: DaftarPemohon,
  },
  {
    path: "/simulasi-biaya-layanan",
    exact: true,
    name: "SIMULASI BIAYA LAYANAN",
    Component: SimulasiBiayaLayanan,
  },
];

export default guest;
