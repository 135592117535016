import React, { useEffect, useState } from "react";
import {
  CCard,
  CButton,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CListGroup,
  CListGroupItem,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import {
  apiSetJadwalBiaya,
  setDataBiayaJadwalPermohonan,
  setDataComboBiayaJenisProperties,
  setDataHitungBiayaV2,
} from "src/config/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { ReactDatePicker, ReactSelect } from "src/components";
import ReactInputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import { IMGNoPhoto } from "src/assets";
import { formatRupiah } from "src/config";

const HitungBiayaJadwalSet = ({ match }) => {
  var PARAMId = match.params.id;
  const history = useHistory();
  const { dataBiayaJadwalSurveyor, formBiayaJadwalPermohonan } = useSelector(
    (state) => state.hitungBiayaDanPenjadwalanReducer
  );
  const { dataComboJenisProperti } = useSelector((state) => state.comboReducer);
  const dispatch = useDispatch();
  const [isBtnOpen, setIsBtnOpen] = useState(false);

  const {
    NoPermohonan,
    IdJenisPermohonan,
    IdKabupaten,
    Nama,
    Email,
    IdSurveyor,
    NoSuratTugas,
    JenisPermohonan,
    AlamatBidangTanah,
    JarakLokasiBidangTanah,
    LuasTanah,
    Wilker,
    Kjsb,
    RpTotal,
    IdJenisProperty,
    RpLainnya,
    JamUkurAwal,
    JamUkurAkhir,
    RpDetails,
  } = formBiayaJadwalPermohonan;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    dispatch(setDataBiayaJadwalPermohonan(PARAMId));
    setValue("IdPermohonan", PARAMId);

    dispatch(setDataComboBiayaJenisProperties("DATA_COMBO_JENIS_PROPERTI"));
  }, [dispatch]);

  useEffect(() => {
    setValue("JamUkurAwal", JamUkurAwal);
    setValue("JamUkurAkhir", JamUkurAkhir);
    setValue("RpLainnya", RpLainnya);
    setValue("LuasTanah", LuasTanah);
    setValue("Diskon", 0);
    setValue("IdSurveyor", IdSurveyor);
    setValue("NoSuratTugas", NoSuratTugas);
  }, [formBiayaJadwalPermohonan]);

  const btnHitungJarak = () => {
    const body = getValues();
    dispatch(
      setDataHitungBiayaV2(
        IdKabupaten,
        IdJenisPermohonan,
        body.IdJenisProperty.value,
        body.LuasTanah,
        setValue
      )
    );
    setIsBtnOpen(true);
  };

  const btnAturBiayaJadwal = (data) =>
    dispatch(apiSetJadwalBiaya(data, history));

  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <CCard>
            <CCardHeader>DATA PEMOHON</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>No. Permohonan</td>
                    <td>
                      : <code>#{NoPermohonan}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>
                      : <b>{Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      : <b>{Email}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>PERMINTAAN</CCardHeader>
            <CCardBody>
              <div className="table table-responsive">
                <table className="w-100">
                  <tr>
                    <td>Jenis Permohonan</td>
                    <td>
                      : <b>{JenisPermohonan}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Bidang Tanah</td>
                    <td>
                      : <b>{AlamatBidangTanah}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Jarak Lokasi</td>
                    <td>
                      :{" "}
                      <b>{JarakLokasiBidangTanah.toLocaleString("id-ID")} KM</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Luas Tanah</td>
                    <td>
                      : <b>{LuasTanah.toLocaleString("id-ID")} M&sup2;</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Wilayah Kerja</td>
                    <td>
                      : <b>{Wilker.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>KJSB</td>
                    <td>
                      : <b>{Kjsb}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div className="col-md-7">
          <CCard>
            <CCardHeader>FORM HITUNG BIAYA & ATUR JADWAL</CCardHeader>
            <form onSubmit={handleSubmit(btnAturBiayaJadwal)}>
              <CCardBody>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>SURVEYOR BERLISENSI</label>
                      <Controller
                        control={control}
                        defaultValue={IdSurveyor}
                        name="IdSurveyor"
                        render={({ field, ref }) => (
                          <ReactSelect
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            options={dataBiayaJadwalSurveyor}
                            value={getValues("IdSurveyor")}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            isDisabled={IdSurveyor !== ""}
                            formatOptionLabel={(srv) => (
                              <div className="d-flex align-items-center">
                                <img
                                  src={srv.image}
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "25px",
                                    objectFit: "cover",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = IMGNoPhoto;
                                  }}
                                  alt="foto-sb"
                                />
                                <span className="ml-3">{srv.label}</span>
                              </div>
                            )}
                            isClearable={true}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.IdSurveyor && (
                        <span className="text-danger">Kolom wajib diisi</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>JADWAL PENGUKURAN</label>
                      <Controller
                        control={control}
                        defaultValue={new Date()}
                        name="JadwalUkur"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Ketikan Jadwal Ukur..."
                            selected={field.value}
                            value={getValues("JadwalUkur")}
                            onChange={(val) => field.onChange(val)}
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date()}
                            customInput={
                              <ReactInputMask
                                mask="99-99-9999"
                                onChange={field.onChange}
                                value={field.value}
                                maskChar=""
                              />
                            }
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.JadwalUkur && (
                        <span className="text-danger">Kolom wajib diisi</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>NO. SURAT TUGAS</label>
                      <input
                        defaultValue={NoSuratTugas}
                        name="NoSuratTugas"
                        className="form-control"
                        placeholder="Ketikan No. Surat Tugas..."
                        {...register("NoSuratTugas", {
                          required: "Kolom wajib diisi",
                        })}
                      />
                      {errors.NoSuratTugas && (
                        <span className="text-danger">
                          {errors.NoSuratTugas?.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>JENIS PROPERTI</label>
                      <Controller
                        control={control}
                        defaultValue={IdJenisProperty}
                        name="IdJenisProperty"
                        render={({ field, ref }) => (
                          <ReactSelect
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            options={dataComboJenisProperti}
                            value={getValues("IdJenisProperty")}
                            onChange={(val) => {
                              field.onChange(val);
                              setIsBtnOpen(false);
                            }}
                            isClearable={true}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.IdJenisProperty && (
                        <span className="text-danger">Kolom wajib diisi</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>LUAS TANAH</label>
                      <NumberFormat
                        thousandSeparator={true}
                        className="form-control"
                        inputmode="numeric"
                        placeholder="Ketikan Luas Tanah"
                        value={LuasTanah}
                        onChange={(e) => {
                          setValue("LuasTanah", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <h6>RINCIAN BIAYA PENGUKURAN</h6>
                    <CListGroup>
                      {RpDetails?.map((v, i) => {
                        return (
                          <CListGroupItem
                            key={i}
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <span>{v.Item}</span>
                            <span>
                              <b> {formatRupiah(v.TotalRp)}</b>
                            </span>
                          </CListGroupItem>
                        );
                      })}
                    </CListGroup>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label>TOTAL</label>
                      <NumberFormat
                        thousandSeparator={true}
                        className="form-control"
                        inputmode="numeric"
                        placeholder="Ketikan Jumlah Rupiah"
                        value={RpTotal}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <CButton
                      color="success"
                      onClick={btnHitungJarak}
                      className="mt-4"
                      block
                    >
                      HITUNG
                    </CButton>
                  </div>
                </div>
              </CCardBody>
              <CCardFooter align="right" className={!isBtnOpen && "d-none"}>
                <CButton color="secondary" onClick={() => history.goBack()}>
                  BATAL
                </CButton>
                <CButton color="primary" type="submit" className="ml-2">
                  ATUR JADWAL
                </CButton>
              </CCardFooter>
            </form>
          </CCard>
        </div>
      </div>
    </>
  );
};

export default HitungBiayaJadwalSet;
