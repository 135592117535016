import React, { useEffect, useState } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable, ReactSelect } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { formatRupiah } from "src/config/function";
import {
  apiVerifikasiPembayaran,
  setDataPermohonans,
  setDataVerifikasiBayar,
} from "src/config/redux/action";
import { Controller, useForm } from "react-hook-form";

const PembayaranVerifikasiBayar = () => {
  const { dataVerifikasiBayars, modalVerifikasiBayar, formVerifikasiBayar } =
    useSelector((state) => state.pembayaranReducer);
  const dispatch = useDispatch();
  const [isCatatan, setIsCatatan] = useState(false);

  const { NoPermohonan, Nama, StatusProses, BuktiBayar, RpBayar } =
    formVerifikasiBayar;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("NoPermohonan", NoPermohonan);
    setValue("Nama", Nama);
    setValue("StatusProses", StatusProses);
    setValue("RpBayar", RpBayar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NoPermohonan, Nama, StatusProses, RpBayar]);

  useEffect(() => {
    dispatch(
      setDataPermohonans("DATA_VERIFIKASI_BAYARS", "verifikasi-pembayaran")
    );
  }, [dispatch]);

  const btnVerifikasiBayar = (data) => dispatch(apiVerifikasiPembayaran(data));

  const handleModal = (idPermohonan) => {
    reset();
    dispatch(setDataVerifikasiBayar(idPermohonan));
    setValue("IdPermohonan", idPermohonan);
  };

  const showModal = () =>
    dispatch({
      type: "MODAL_VERIFIKASI_BAYAR",
      payload: !modalVerifikasiBayar,
    });

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleModal(rowData.IdPermohonan)}
            >
              <CIcon name="cil-check" /> VERIFIKASI
            </button>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "STATUS BAYAR",
      field: "StatusBayar",
      cellStyle: {
        width: 170,
        minWidth: 170,
      },
      headerStyle: {
        width: 170,
        minWidth: 170,
      },
      render: (rowData) => (
        <>
          {rowData.StatusBayar === "Lunas" && (
            <span className="badge badge-success">{rowData.StatusBayar}</span>
          )}
          {rowData.StatusBayar === "Dalam Proses" && (
            <span className="badge badge-info">{rowData.StatusBayar}</span>
          )}
          {rowData.StatusBayar === "Pending" && (
            <span className="badge badge-warning">{rowData.StatusBayar}</span>
          )}
          {rowData.StatusBayar === "Ditolak" && (
            <span className="badge badge-danger">{rowData.StatusBayar}</span>
          )}
        </>
      ),
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.AlamatBidangTanah}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  const dataJsonLunas = [
    {
      value: "1",
      label: "LUNAS",
    },
    {
      value: "-1",
      label: "TIDAK LUNAS",
    },
  ];

  return (
    <>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataVerifikasiBayars}
            title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
          />
        </CCard>
      </div>
      <CModal show={modalVerifikasiBayar} onClose={showModal}>
        <CModalHeader closeButton>DETAIL PERMOHONAN</CModalHeader>
        <form id="form" onSubmit={handleSubmit(btnVerifikasiBayar)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>
                <div className="form-group">
                  <label>BUKTI BAYAR</label>
                  <div>
                    <img
                      src={BuktiBayar}
                      alt="bukti bayar"
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        backgroundColor: "grey",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <br />
                  <a href={BuktiBayar} target="_blank">
                    Tampilkan Besar
                  </a>
                </div>
                <div className="form-group">
                  <label>JUMLAH TAGIHAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={formatRupiah(RpBayar)}
                  />
                </div>
                <div className="form-group">
                  <label>STATUS BAYAR</label>
                  <Controller
                    control={control}
                    defaultValue={StatusProses}
                    name="StatusProses"
                    render={({ field, value, name, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonLunas}
                        value={getValues("StatusProses")}
                        onChange={(val) => {
                          field.onChange(val);
                          setIsCatatan(val.value === "-1");
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.StatusProses && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
                <div className="form-group">
                  <label>CATATAN</label>
                  <textarea
                    name="Catatan"
                    rows={5}
                    className="form-control"
                    placeholder="Ketikan Catatan..."
                    {...register("Catatan", {
                      required: isCatatan,
                    })}
                  />
                  {errors.Catatan && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              VERIFIKASI
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </>
  );
};

export default PembayaranVerifikasiBayar;
