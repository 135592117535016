import React, { useEffect } from "react";
import { CCard, CTooltip } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { setDataPermohonans, setDataPermohonan } from "src/config/redux/action";
import { useHistory } from "react-router-dom";

const SemuaPermohonan = () => {
  const history = useHistory();
  const { dataPermohonans, modalPelayananPreviewBerkas } = useSelector(
    (state) => state.permohonanReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_PERMOHONANS", "semua"));
  }, [dispatch]);

  const btnPreview = (idPermohonan) => {
    dispatch(setDataPermohonan(idPermohonan));
    dispatch({
      type: "MODAL_PELAYANAN_PREVIEW_BERKAS",
      payload: !modalPelayananPreviewBerkas,
    });
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => (
        <div>
          {rowData.LastStatus === 9 &&
            (rowData.LastStatusProses === -1 ||
              rowData.LastStatusProses === 0) && (
              <CTooltip content="Perbarui Data" placement="right">
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={() =>
                    history.push(`/semua/edit/${rowData.IdPermohonan}`)
                  }
                >
                  <CIcon name="cil-pencil" />
                </button>
              </CTooltip>
            )}
          <CTooltip content="Lihat" placement="right">
            <button
              type="button"
              className="btn btn-sm btn-dark ml-1"
              onClick={() => btnPreview(rowData.IdPermohonan)}
            >
              <CIcon name="cil-search" />
            </button>
          </CTooltip>
        </div>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          <span className="badge badge-warning">
            {rowData.StatusProses || "-"}
          </span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
  ];

  return (
    <div className="mb-4">
      <CCard>
        <DataTable
          columns={columns}
          data={dataPermohonans}
          title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
        />
      </CCard>
    </div>
  );
};

export default SemuaPermohonan;
