import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./CustomIcon.css";

//wajib brow
import marker from "leaflet/dist/images/marker-icon.png";
import marker2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});
//end wajib brow

const center = {
  lat: -6.5404194,
  lng: 106.814736,
};

const DraggableMarker = () => {
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    >
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? "Marker is draggable" + position
            : "Click here to make marker draggable"}
        </span>
      </Popup>
    </Marker>
  );
};

const Leaflet = ({ ...props }) => {
  const geoJsonLayer = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geoData = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [106.79599888622761, -6.526642017051125],
        },
      },
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [106.79610919207335, -6.526636021192793],
        },
      },
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [106.7961112037301, -6.526676326683575],
        },
      },
      {
        type: "Feature",
        properties: {
          "marker-color": "#7e7e7e",
          "marker-size": "medium",
          "marker-symbol": "",
        },
        geometry: {
          type: "Point",
          coordinates: [106.79599955677985, -6.526681989438228],
        },
      },
    ],
  };

  useEffect(() => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers().addData(geoData);
    }
  }, [geoData]);

  const onEachFeature = (feature, layer) => {
    console.log("woi", layer.feature.properties);
    var html = "";
    Object.keys(layer.feature.properties).map((v, i) => {
      html += feature.properties[v];
    });
    layer.bindPopup(` <Popup minWidth={90}><p>${html}</p></Popup> `);
  };
  return (
    <div>
      <MapContainer
        className="map"
        style={{ width: "100%", height: 500 }}
        center={center}
        zoom={18}
        maxZoom={18}
        scrollWheelZoom={true}
        {...props}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
        {/* <GeoJSON
            data={geoData}
            color='red'
            fillColor='green'
            weight={1}
            onEachFeature={onEachFeature}
          /> */}
        {/* {geoData.features.map((feature, index) => {
            return (
              <FeatureGroup color="purple" key={index}>
                <Popup>
                  {
                    Object.keys(feature.properties).map((v, i) => {
                      return (
                        <p>{v} - {feature.properties[v]}</p>
                      )
                    })
                  }
                  
                </Popup>
                <Marker
                  position={[
                    feature.geometry.coordinates[1],
                    feature.geometry.coordinates[0]
                  ]}
                  draggable={true}
                >
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              </FeatureGroup>
            );
        })} */}
      </MapContainer>
    </div>
  );
};

export default Leaflet;
