import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setImgPreview } from "../globalAction";

export const setDataIklans = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/App/GetIklans`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_IKLANS", payload: data.Data });
      } else {
        if (data.ReturnMessage !== "data tidak ada")
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataIklan = (idIklan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_IKLAN" });
    http.get(`${URLSVC}/App/GetIklan?idIklan=${idIklan}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({ type: "DATA_IKLAN", payload: data.Data });

        dispatch(setFormIklan("IdIklan", data.Data.IdIklan));
        dispatch(setFormIklan("Judul", data.Data.Judul));
        dispatch(setFormIklan("Kontak", data.Data.Kontak));
        dispatch(
          setFormIklan("MinTanggalTayang", new Date(data.Data.MinTanggalTayang))
        );
        dispatch(
          setFormIklan("MaxTanggalTayang", new Date(data.Data.MaxTanggalTayang))
        );
        dispatch(setFormIklan("NamaFile", data.Data.NamaFile));

        const SELECT = {
          TipeKontak: {
            value: data.Data.TipeKontak.Id,
            label: data.Data.TipeKontak.Nama,
          },
          Status: {
            value: data.Data.Status.Id,
            label: data.Data.Status.Nama,
          },
        };
        dispatch(setFormIklan("IsTipeKontak", data.Data.TipeKontak.Id));
        dispatch(setFormIklan("TipeKontak", SELECT.TipeKontak));
        dispatch(setFormIklan("Status", SELECT.Status));

        dispatch(
          setImgPreview(
            "FILE_IMAGE_IKLAN_PREVIEW",
            `${data.Data.NamaFile}?${new Date().getTime()}`
          )
        );

        dispatch({ type: "MODAL_IKLAN", payload: true });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setFormIklan = (formType, formValue) => {
  return { type: "FORM_IKLAN", formType, formValue };
};

export const apiAddIklan = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Judul", iData.Judul);
    fd.append("TipeKontak", iData.TipeKontak.value);
    fd.append(
      "Kontak",
      iData.TipeKontak.value === 1 ? iData.KontakTelepon : iData.KontakEmail
    );
    fd.append("MinTanggalTayang", FUNCDateToString(iData.MinTanggalTayang));
    fd.append("MaxTanggalTayang", FUNCDateToString(iData.MaxTanggalTayang));
    // fd.append("Status", iData.Status.value);
    fd.append("FileUpload", iData.FileUpload);

    http.post(`${URLSVC}/App/IklanAdd`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil menambah data`,
          "success"
        ).then(function () {
          dispatch({ type: "MODAL_IKLAN", payload: false });
          dispatch(setDataIklans());
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiUpdateIklan = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdIklan", iData.IdIklan);
    fd.append("Judul", iData.Judul);
    fd.append("TipeKontak", iData.TipeKontak.value);
    fd.append(
      "Kontak",
      iData.TipeKontak.value === 1 ? iData.KontakTelepon : iData.KontakEmail
    );
    fd.append("MinTanggalTayang", FUNCDateToString(iData.MinTanggalTayang));
    fd.append("MaxTanggalTayang", FUNCDateToString(iData.MaxTanggalTayang));
    fd.append("Status", iData.Status.value);
    fd.append("FileUpload", iData.FileUpload);

    http.post(`${URLSVC}/App/IklanEdit`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil memperbarui data`,
          "success"
        ).then(function () {
          dispatch({ type: "MODAL_IKLAN", payload: false });
          dispatch(setDataIklans());
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiDeleteIklan = (idIklan, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus iklan (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .post(`${URLSVC}/App/IklanDelete?idIklan=${idIklan}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil hapus iklan`,
                "success"
              ).then(function () {
                dispatch(setDataIklans());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiApproveIklan = (idIklan) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan menyetujui iklan ini!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .post(`${URLSVC}/App/IklanApprove?idIklan=${idIklan}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil menyetujui iklan`,
                "success"
              ).then(function () {
                dispatch(setDataIklans());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiRejectIklan = (idIklan) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan menolak iklan ini!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
          loadingBar(true);
        http
          .post(`${URLSVC}/App/IklanReject?idIklan=${idIklan}`)
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil menolak iklan`,
                "success"
              ).then(function () {
                dispatch(setDataIklans());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
